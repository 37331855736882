import Image from './components/Image';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import SvgIcon from '@mui/material/SvgIcon';
import BasicTooltip from "../../components/BasicTooltip";

export const SocialMediaSites = {
  Facebook: 'facebook',
  Instagram: 'instagram',
  X: 'X',
}

export const getColor = (value) => {
  if (value < 1) {
    return '#ff2525'
  } else if (value <= 1) {
    return '#ff2525'
  } else if (value <= 2) {
    return '#ff7c22'
  } else if (value <= 3) {
    return '#77cfdb'
  } else if (value <= 4) {
    return '#77db7a'
  } else if (value > 4) {
    return '#80fa85'
  }
}

export const renderHeader = (params) => {
  if (params.field === 'weight') {
    return (
      <span style={{fontWeight: '500', textTransform: 'uppercase'}}>Weight</span>
    )
  } else if (params.field === 'explanation') {
    return (
      <span style={{fontWeight: '500', textTransform: 'uppercase'}}>Info</span>
    )
  } else {
    return (
      <div className={'col-1-1 flex-center-all'}>
        <Image
          alt="partner-logo"
          src={`https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/${params.field.split('|')[0]}.png`}
          style={{maxWidth: '96px', height: 'auto'}}
        />
        <div className={'status-flag'}
           style={{backgroundColor: '#f2f2f2'}}>{params.colDef.headerName.split('|')[1]}
        </div>
      </div>
    )
  }
}

export const renderCell = (params) => {
  if (params.row.hierarchy.length === 2) {
    // params.isEditable = true
  }
  if (params.value === undefined) {
    return (
      <div></div>
    )
  } else {
    return (
      <>
        <div className={'col-1-1 flex-center-all'}>
          {params.isEditable === true ?
            <div
              className={'edit-icon'}
              style={{
                position: 'absolute',
                top: '14px',
                right: '16px',
                zIndex: 10,
                color: '#666',
              }}>
              <EditIcon
                style={{scale: 0.5}}
              />
            </div>
            :
            <div
              className={'edit-icon'}
              style={{
                position: 'absolute',
                top: '15px',
                right: '16px',
                zIndex: 10,
                color: '#666',
              }}>
              {(params.field !== 'explanation') ?
                <LockIcon
                  style={{scale: 0.2, opacity: 0.2}}
                /> : null}
            </div>
          }

          <h4 style={{
            position: 'absolute',
            fontSize: '24px',
            fontFamily: "'Roboto Slab', serif'",
            opacity: .7,
            fontWeight: 400,
            zIndex: 10
          }}>
            {(params.field === 'explanation') || (params.id !== 0) ? null : parseInt(params.value) + '%'}
          </h4>

          {(params.id !== 0) && (params.field !== 'explanation') ? [...Array(parseInt(params.value))].map((item, index) => {
            return (
              <div className={'bar-block'} key={index}
                 style={{backgroundColor: getColor(parseInt(params.value))}}></div>
            )
          }) : null}

          {(params.field === 'explanation') && (params.value !== '') ?
            <BasicTooltip title={params.value}/> : null}

        </div>
      </>
    )
  }
}

export const transformData = (data) => {

  data.columns[1]['width'] = 54

  // append renderCell to specific columns
  data.columns.forEach((column, index) => {
    column['renderCell'] = renderCell
    column['renderHeader'] = renderHeader
    column['sortable'] = false
    column['editable'] = true
    column['type'] = 'singleSelect'
    column['valueOptions'] = [0, 1, 2, 3, 4, 5]

    // if (column.field === '8MX40j5kyCwZbuWt6g53|S8mmFUHsGvee2kNd2BnN') {
      // data.columns.splice(index, 1)
      // console.log(index)
    // }
    if (column.field === 'explanation') {
      // alert('explanation')
      column['editable'] = false
    }
    if (column.field === 'weight') {
      // alert('explanation')
      column['editable'] = false
    }

    // data.columns = data.columns.filter(e => e.field !== '8MX40j5kyCwZbuWt6g53|S8mmFUHsGvee2kNd2BnN')
  })

  return data
}

export const hackGrid = () => {
  setTimeout(() => {
    let muiKeyEl = document.querySelectorAll('.MuiDataGrid-main div:nth-child(3)')
    muiKeyEl.forEach((el) => {
      if (el.innerHTML === 'MUI X Missing license key') {
        el.innerHTML = ''
      }
    })
    document.querySelectorAll('.grid-wrap').forEach((el) => {
      el.style.opacity = 1
    })
  }, 10)
}

export const hackGridDetail = () => {
  setTimeout(() => {
    let muiKeyEl = document.querySelectorAll('.MuiDataGrid-detailPanel div:nth-child(3)')
    muiKeyEl.forEach((el) => {
      if (el.innerHTML === 'MUI X Missing license key') {
        el.innerHTML = ''
      }
    })
    document.querySelectorAll('.grid-wrap').forEach((el) => {
      el.style.opacity = 1
    })
  }, 10)
}

export function SortedDescendingIcon() {
  return <ArrowDropUp className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropDown className="icon" />;
}

export function UnsortedIcon() {
  return (
    <SvgIcon>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 4L13.4641 8.5H6.5359L10 4Z" fill="#8B919A"/>
        <path d="M10 16L13.4641 11.5H6.5359L10 16Z" fill="#8B919A"/>
      </svg>
    </SvgIcon>
  )
}

export const abbreviatedNumber = (num) => {
  return Intl.NumberFormat('en-US', {
    notation: "compact",
    maximumFractionDigits: 1
  }).format(num);
};

export const numberWithPlusMinus = (num) => {
  return Intl.NumberFormat('en-US', {
    signDisplay: "always",
  }).format(num);
};