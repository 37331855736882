import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import SignInButton from "../components/SignInButton";
import {Navigate} from "react-router-dom";
import badgeFusion from "../images/badge-fusion.png";
import heroBg from "../images/hero-bg.png";
import logoGroupM from "../images/logo-groupm.png";
import ReactGA from "react-ga4";
import '../stylesheets/AuthPage.css';

function AuthPage (props) {

  const { title } = props;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAuthenticated = useIsAuthenticated();
  const [hasClickedOnLogin, setHasClickedOnLogin] = useState(false);

  return (
    <div className={hasClickedOnLogin ? 'fly-out' : null} style={{height: '100%'}}>
      <img src={badgeFusion} alt="logo Fusion" id="badge-fusion-auth" />
      <img src={logoGroupM} alt="logo GroupM" id="logo-groupm-auth" />
      <div className={'col-1-1 flex-center-all full-bg flex-direction-column'} style={{height: '100%', backgroundImage: `url(${heroBg})`}}>
        <div className={'auth-box'}>
          <p>Fusion</p>
          <h1 style={{color: 'white', fontWeight: '600'}}>Insights</h1>
          { isAuthenticated ? <Navigate replace to={"/home"} /> : <SignInButton setHasClickedOnLogin={setHasClickedOnLogin}/> }
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
