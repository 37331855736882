export const RevenueData = [
  {
    "year": 2017,
    "value": 196,
  },
  {
    "year": 2018,
    "value": 76,
  },
  {
    "year": 2019,
    "value": 189,
  },
  {
    "year": 2020,
    "value": 42,
  },
  {
    "year": 2021,
    "value": 35,
  },
  {
    "year": 2022,
    "value": 113,
  },
  {
    "year": 2023,
    "value": 150,
  }
]

export const AverageOrderValueData = [
  {
    "year": 2017,
    "data": [
      {
        x: "carrefour.fr",
        value : 72.5,
      },
      {
        x: "Average AOV: France",
        value : 59.5,
      },
      {
        x: "Average AOV: Food & Personal Care",
        value : 57.5,
      },
    ],
  },
  {
    "year": 2018,
    "data": [
      {
        x: "carrefour.fr",
        value : 12.5,
      },
      {
        x: "Average AOV: France",
        value : 55.7,
      },
      {
        x: "Average AOV: Food & Personal Care",
        value : 33.0,
      },
    ],
  },
  {
    "year": 2019,
    "data": [
      {
        x: "carrefour.fr",
        value : 88.5,
      },
      {
        x: "Average AOV: France",
        value : 22.7,
      },
      {
        x: "Average AOV: Food & Personal Care",
        value : 44.0,
      },
    ],
  },
  {
    "year": 2020,
    "data": [
      {
        x: "carrefour.fr",
        value : 57.5,
      },
      {
        x: "Average AOV: France",
        value : 72.7,
      },
      {
        x: "Average AOV: Food & Personal Care",
        value : 59.0,
      },
    ],
  },
  {
    "year": 2021,
    "data": [
      {
        x: "carrefour.fr",
        value : 66.5,
      },
      {
        x: "Average AOV: France",
        value : 12.7,
      },
      {
        x: "Average AOV: Food & Personal Care",
        value : 23.0,
      },
    ],
  },
  {
    "year": 2022,
    "data": [
      {
        x: "carrefour.fr",
        value : 88.5,
      },
      {
        x: "Average AOV: France",
        value : 77.7,
      },
      {
        x: "Average AOV: Food & Personal Care",
        value : 66.0,
      },
    ],
  },
  {
    "year": 2023,
    "data": [
      {
        x: "carrefour.fr",
        value : 33.5,
      },
      {
        x: "Average AOV: France",
        value : 44.7,
      },
      {
        x: "Average AOV: Food & Personal Care",
        value : 55.0,
      },
    ],
  }
]

export const NetSalesByCategoryData = [
  {
    "year": 2018,
    "data": [
      {
        "id": "Food & Personal Care",
        "label": "Food & Personal Care",
        "value": 47,
        "data": [
          {
            "id": "Food & Beverage",
            "value": 35.7
          },
          {
            "id": "Personal Care",
            "value": 35.7
          },
        ],
      },
      {
        "id": "Fashion",
        "label": "Fashion",
        "value": 6,
        "data": [
          {
            "id": "aaa",
            "value": 3.5
          },
          {
            "id": "bbb",
            "value": 8
          },
          {
            "id": "ccc",
            "value": 6.2
          },
          {
            "id": "ddd",
            "value": 5
          },
        ],
      },
      {
        "id": "Toys, Hobby & DIY",
        "label": "Toys, Hobby & DIY",
        "value": 30,
        "data": [
          {
            "id": "DIY Garden & Pets",
            "value": 12
          },
          {
            "id": "Hobby & Stationary",
            "value": 4
          },
          {
            "id": "Sports & Outdoors",
            "value": 4
          },
          {
            "id": "Toys & Baby",
            "value": 10
          },
        ],
      },
      {
        "id": "Furniture & Appliances",
        "label": "Furniture & Appliances",
        "value": 8,
        "data": [
          {
            "id": "eee",
            "value": 9.5
          },
          {
            "id": "fff",
            "value": 4.5
          },
          {
            "id": "ggg",
            "value": 8.8
          },
        ],
      },
      {
        "id": "Electronics & Media",
        "label": "Electronics & Media",
        "value": 9,
        "data": [
          {
            "id": "hhh",
            "value": 10.5
          },
          {
            "id": "iii",
            "value": 8.4
          },
          {
            "id": "jjj",
            "value": 21.3
          },
          {
            "id": "kkk",
            "value": 6.1
          },
          {
            "id": "lll",
            "value": 7.2
          },
        ],
      },
    ]
  },
  {
    "year": 2019,
    "data": [
      {
        "id": "Food & Personal Care",
        "label": "Food & Personal Care",
        "value": 47,
        "data": [
          {
            "id": "Food & Beverage",
            "value": 35.7
          },
          {
            "id": "Personal Care",
            "value": 35.7
          },
        ],
      },
      {
        "id": "Fashion",
        "label": "Fashion",
        "value": 6,
        "data": [
          {
            "id": "aaa",
            "value": 3.5
          },
          {
            "id": "bbb",
            "value": 8
          },
          {
            "id": "ccc",
            "value": 6.2
          },
          {
            "id": "ddd",
            "value": 5
          },
        ],
      },
      {
        "id": "Toys, Hobby & DIY",
        "label": "Toys, Hobby & DIY",
        "value": 30,
        "data": [
          {
            "id": "DIY Garden & Pets",
            "value": 12
          },
          {
            "id": "Hobby & Stationary",
            "value": 4
          },
          {
            "id": "Sports & Outdoors",
            "value": 4
          },
          {
            "id": "Toys & Baby",
            "value": 10
          },
        ],
      },
      {
        "id": "Furniture & Appliances",
        "label": "Furniture & Appliances",
        "value": 8,
        "data": [
          {
            "id": "eee",
            "value": 9.5
          },
          {
            "id": "fff",
            "value": 4.5
          },
          {
            "id": "ggg",
            "value": 8.8
          },
        ],
      },
      {
        "id": "Electronics & Media",
        "label": "Electronics & Media",
        "value": 9,
        "data": [
          {
            "id": "hhh",
            "value": 10.5
          },
          {
            "id": "iii",
            "value": 8.4
          },
          {
            "id": "jjj",
            "value": 21.3
          },
          {
            "id": "kkk",
            "value": 6.1
          },
          {
            "id": "lll",
            "value": 7.2
          },
        ],
      },
    ]
  },
  {
    "year": 2020,
    "data": [
      {
        "id": "Food & Personal Care",
        "label": "Food & Personal Care",
        "value": 47,
        "data": [
          {
            "id": "Food & Beverage",
            "value": 35.7
          },
          {
            "id": "Personal Care",
            "value": 35.7
          },
        ],
      },
      {
        "id": "Fashion",
        "label": "Fashion",
        "value": 6,
        "data": [
          {
            "id": "aaa",
            "value": 3.5
          },
          {
            "id": "bbb",
            "value": 8
          },
          {
            "id": "ccc",
            "value": 6.2
          },
          {
            "id": "ddd",
            "value": 5
          },
        ],
      },
      {
        "id": "Toys, Hobby & DIY",
        "label": "Toys, Hobby & DIY",
        "value": 30,
        "data": [
          {
            "id": "DIY Garden & Pets",
            "value": 12
          },
          {
            "id": "Hobby & Stationary",
            "value": 4
          },
          {
            "id": "Sports & Outdoors",
            "value": 4
          },
          {
            "id": "Toys & Baby",
            "value": 10
          },
        ],
      },
      {
        "id": "Furniture & Appliances",
        "label": "Furniture & Appliances",
        "value": 8,
        "data": [
          {
            "id": "eee",
            "value": 9.5
          },
          {
            "id": "fff",
            "value": 4.5
          },
          {
            "id": "ggg",
            "value": 8.8
          },
        ],
      },
      {
        "id": "Electronics & Media",
        "label": "Electronics & Media",
        "value": 9,
        "data": [
          {
            "id": "hhh",
            "value": 10.5
          },
          {
            "id": "iii",
            "value": 8.4
          },
          {
            "id": "jjj",
            "value": 21.3
          },
          {
            "id": "kkk",
            "value": 6.1
          },
          {
            "id": "lll",
            "value": 7.2
          },
        ],
      },
    ]
  },
  {
    "year": 2021,
    "data": [
      {
        "id": "Food & Personal Care",
        "label": "Food & Personal Care",
        "value": 47,
        "data": [
          {
            "id": "Food & Beverage",
            "value": 35.7
          },
          {
            "id": "Personal Care",
            "value": 35.7
          },
        ],
      },
      {
        "id": "Fashion",
        "label": "Fashion",
        "value": 6,
        "data": [
          {
            "id": "aaa",
            "value": 3.5
          },
          {
            "id": "bbb",
            "value": 8
          },
          {
            "id": "ccc",
            "value": 6.2
          },
          {
            "id": "ddd",
            "value": 5
          },
        ],
      },
      {
        "id": "Toys, Hobby & DIY",
        "label": "Toys, Hobby & DIY",
        "value": 30,
        "data": [
          {
            "id": "DIY Garden & Pets",
            "value": 12
          },
          {
            "id": "Hobby & Stationary",
            "value": 4
          },
          {
            "id": "Sports & Outdoors",
            "value": 4
          },
          {
            "id": "Toys & Baby",
            "value": 10
          },
        ],
      },
      {
        "id": "Furniture & Appliances",
        "label": "Furniture & Appliances",
        "value": 8,
        "data": [
          {
            "id": "eee",
            "value": 9.5
          },
          {
            "id": "fff",
            "value": 4.5
          },
          {
            "id": "ggg",
            "value": 8.8
          },
        ],
      },
      {
        "id": "Electronics & Media",
        "label": "Electronics & Media",
        "value": 9,
        "data": [
          {
            "id": "hhh",
            "value": 10.5
          },
          {
            "id": "iii",
            "value": 8.4
          },
          {
            "id": "jjj",
            "value": 21.3
          },
          {
            "id": "kkk",
            "value": 6.1
          },
          {
            "id": "lll",
            "value": 7.2
          },
        ],
      },
    ]
  },
  {
    "year": 2022,
    "data": [
      {
        "id": "Food & Personal Care",
        "label": "Food & Personal Care",
        "value": 20,
        "data": [
          {
            "id": "Food & Beverage",
            "value": 35.7
          },
          {
            "id": "Personal Care",
            "value": 35.7
          },
        ],
      },
      {
        "id": "Fashion",
        "label": "Fashion",
        "value": 20,
        "data": [
          {
            "id": "aaa",
            "value": 3.5
          },
          {
            "id": "bbb",
            "value": 8
          },
          {
            "id": "ccc",
            "value": 6.2
          },
          {
            "id": "ddd",
            "value": 5
          },
        ],
      },
      {
        "id": "Toys, Hobby & DIY",
        "label": "Toys, Hobby & DIY",
        "value": 20,
        "data": [
          {
            "id": "DIY Garden & Pets",
            "value": 12
          },
          {
            "id": "Hobby & Stationary",
            "value": 4
          },
          {
            "id": "Sports & Outdoors",
            "value": 4
          },
          {
            "id": "Toys & Baby",
            "value": 10
          },
        ],
      },
      {
        "id": "Furniture & Appliances",
        "label": "Furniture & Appliances",
        "value": 20,
        "data": [
          {
            "id": "eee",
            "value": 9.5
          },
          {
            "id": "fff",
            "value": 4.5
          },
          {
            "id": "ggg",
            "value": 8.8
          },
        ],
      },
      {
        "id": "Electronics & Media",
        "label": "Electronics & Media",
        "value": 20,
        "data": [
          {
            "id": "hhh",
            "value": 10.5
          },
          {
            "id": "iii",
            "value": 8.4
          },
          {
            "id": "jjj",
            "value": 21.3
          },
          {
            "id": "kkk",
            "value": 6.1
          },
          {
            "id": "lll",
            "value": 7.2
          },
        ],
      },
    ]
  },
  {
    "year": 2023,
    "data": [
      {
        "id": "Food & Personal Care",
        "label": "Food & Personal Care",
        "value": 47,
        "data": [
          {
            "id": "Food & Beverage",
            "value": 35.7
          },
          {
            "id": "Personal Care",
            "value": 35.7
          },
        ],
      },
      {
        "id": "Fashion",
        "label": "Fashion",
        "value": 6,
        "data": [
          {
            "id": "aaa",
            "value": 3.5
          },
          {
            "id": "bbb",
            "value": 8
          },
          {
            "id": "ccc",
            "value": 6.2
          },
          {
            "id": "ddd",
            "value": 5
          },
        ],
      },
      {
        "id": "Toys, Hobby & DIY",
        "label": "Toys, Hobby & DIY",
        "value": 30,
        "data": [
          {
            "id": "DIY Garden & Pets",
            "value": 12
          },
          {
            "id": "Hobby & Stationary",
            "value": 4
          },
          {
            "id": "Sports & Outdoors",
            "value": 4
          },
          {
            "id": "Toys & Baby",
            "value": 10
          },
        ],
      },
      {
        "id": "Furniture & Appliances",
        "label": "Furniture & Appliances",
        "value": 8,
        "data": [
          {
            "id": "eee",
            "value": 9.5
          },
          {
            "id": "fff",
            "value": 4.5
          },
          {
            "id": "ggg",
            "value": 8.8
          },
        ],
      },
      {
        "id": "Electronics & Media",
        "label": "Electronics & Media",
        "value": 9,
        "data": [
          {
            "id": "hhh",
            "value": 10.5
          },
          {
            "id": "iii",
            "value": 8.4
          },
          {
            "id": "jjj",
            "value": 21.3
          },
          {
            "id": "kkk",
            "value": 6.1
          },
          {
            "id": "lll",
            "value": 7.2
          },
        ],
      },
    ]
  },
]

export const SeaData = [
  {
    "id": "SEA",
    "data": [
      {
        "x": '10/01/21',
        "y": 1000,
      },
      {
        "x": '11/01/21',
        "y": 1500,
      },
      {
        "x": '12/01/21',
        "y": 1500,
      },
      {
        "x": '01/01/22',
        "y": 100,
      },
      {
        "x": '02/01/22',
        "y": 1000,
      },
      {
        "x": '03/01/22',
        "y": 1400,
      },
      {
        "x": '04/01/22',
        "y": 500,
      },
      {
        "x": '05/01/22',
        "y": 50,
      },
      {
        "x": '06/01/22',
        "y": 750,
      },
      {
        "x": '07/01/22',
        "y": 2000,
      },
      {
        "x": '08/1/22',
        "y": 1500,
      },
      {
        "x": '09/1/22',
        "y": 1600,
      },
      {
        "x": '10/01/22',
        "y": 1000,
      },
      {
        "x": '11/01/22',
        "y": 1500,
      },
      {
        "x": '12/01/22',
        "y": 1500,
      },
      {
        "x": '01/01/23',
        "y": 100,
      },
      {
        "x": '02/01/23',
        "y": 1000,
      },
      {
        "x": '03/01/23',
        "y": 1400,
      },
      {
        "x": '04/01/23',
        "y": 500,
      },
      {
        "x": '05/01/23',
        "y": 50,
      },
      {
        "x": '06/01/23',
        "y": 750,
      },
      {
        "x": '07/01/23',
        "y": 2000,
      },
      {
        "x": '08/1/23',
        "y": 1500,
      },
      {
        "x": '09/1/23',
        "y": 1600,
      }
    ]
  }
]

export const SeoData = [
  {
    "id": "SEA",
    "data": [
      {
        "x": '10/01/21',
        "y": 1000,
      },
      {
        "x": '11/01/21',
        "y": 1200,
      },
      {
        "x": '12/01/21',
        "y": 1300,
      },
      {
        "x": '01/01/22',
        "y": 700,
      },
      {
        "x": '02/01/22',
        "y": 500,
      },
      {
        "x": '03/01/22',
        "y": 1400,
      },
      {
        "x": '04/01/22',
        "y": 900,
      },
      {
        "x": '05/01/22',
        "y": 500,
      },
      {
        "x": '06/01/22',
        "y": 600,
      },
      {
        "x": '07/01/22',
        "y": 1300,
      },
      {
        "x": '08/1/22',
        "y": 1500,
      },
      {
        "x": '09/1/22',
        "y": 1000,
      },
      {
        "x": '10/01/22',
        "y": 1600,
      },
      {
        "x": '11/01/22',
        "y": 600,
      },
      {
        "x": '12/01/22',
        "y": 1200,
      },
      {
        "x": '01/01/23',
        "y": 300,
      },
      {
        "x": '02/01/23',
        "y": 600,
      },
      {
        "x": '03/01/23',
        "y": 800,
      },
      {
        "x": '04/01/23',
        "y": 500,
      },
      {
        "x": '05/01/23',
        "y": 1500,
      },
      {
        "x": '06/01/23',
        "y": 750,
      },
      {
        "x": '07/01/23',
        "y": 1800,
      },
      {
        "x": '08/1/23',
        "y": 1100,
      },
      {
        "x": '09/1/23',
        "y": 600,
      }
    ]
  }
]

export const CompetitiveLandscapeData = [
  {year: 2022, 'Online Store': 'coursesu.com', 'Net Sales': '$529MM', 'Market Share': '3.6%'},
  {year: 2022, 'Online Store': 'auchan.fr', 'Net Sales': '$428MM', 'Market Share': '3.3%'},
  {year: 2022, 'Online Store': 'amazon.fr', 'Net Sales': '$322MM', 'Market Share': '2.9%'},
  {year: 2022, 'Online Store': 'carrefour.fr', 'Net Sales': '$289MM', 'Market Share': '2.4%'},
  {year: 2022, 'Online Store': 'sephora.fr', 'Net Sales': '$219MM', 'Market Share': '2.2%'},
  {year: 2023, 'Online Store': 'coursesu.com', 'Net Sales': '$629MM', 'Market Share': '13.6%'},
  {year: 2023, 'Online Store': 'auchan.fr', 'Net Sales': '$528MM', 'Market Share': '13.3%'},
  {year: 2023, 'Online Store': 'amazon.fr', 'Net Sales': '$422MM', 'Market Share': '12.9%'},
  {year: 2023, 'Online Store': 'carrefour.fr', 'Net Sales': '$389MM', 'Market Share': '12.4%'},
  {year: 2023, 'Online Store': 'sephora.fr', 'Net Sales': '$319MM', 'Market Share': '12.2%'},
]

export const SocialMediaMetricsData = [
  {Platform: 'facebook', 'Account': 'coursesu.com', 'Follower': 11300, 'New Follower': 32663, 'Activity Score': 2.8},
  {Platform: 'X', 'Account': 'auchan.fr', 'Follower': 125000, 'New Follower': 1275, 'Activity Score': 2.6},
  {Platform: 'Instagram', 'Account': 'amazon.fr', 'Follower': 113000, 'New Follower': 3188, 'Activity Score': 3.2},
]
export const TrafficAnalyticsData = [
  {
    "month": '09/01/2022',
    "value": 250,
  },
  {
    "month": '10/01/2022',
    "value": 800,
  },
  {
    "month": '11/01/2022',
    "value": 150,
  },
]

