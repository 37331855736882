import React from "react";
import "../stylesheets/SideBar.css";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DiamondIcon from '@mui/icons-material/Diamond';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import badgeFusion from "../images/badge-fusion.png";


const SideBar = (props) => {
  return (
    <div className="side-bar">
      <a href={'/home'} aria-label={'Home'} className="sidebar-badge-fusion">
        <img src={badgeFusion} alt="logo Fusion" />
      </a>
      <div className="icon-wrap-container">
        <a className={'icon-wrap icon-active'} href={'/home'}>
          <ScatterPlotIcon/>
          <span>Insights</span>
        </a>
        <a className={'icon-wrap'} href={'https://fusionplatform.io/platform/trends'} target={'_blank'} rel={'noreferrer'}>
          <TrendingUpIcon/>
          <span>Trends</span>
        </a>
        <a className={'icon-wrap'} href={'https://fusionplatform.io/platform/ls'} target={'_blank'} rel={'noreferrer'}>
          <DiamondIcon/>
          <span>Landscape</span>
        </a>
      </div>
    </div>
  )
}

export default SideBar;