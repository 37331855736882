import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { AverageOrderValueData } from '../../../../data/NivoData';
import { ResponsiveBar } from '@nivo/bar';
import { cardContentMargin } from '../../styles';
import './styles/AverageOrderValue.css';

const AverageOrderValue = () => {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [data, setData] = useState([]);

  useEffect(() => {
    setYears(AverageOrderValueData.map((item) => item.year));
  }, []);

  useEffect(() => {
    const selectedData = AverageOrderValueData.filter((a) => a.year === selectedYear);

    if (selectedData.length) {
      setData(selectedData[0].data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const handleChange = (event) => {
    setSelectedYear(event.detail.value);
  };

  return (
    <>
      <div className="average-order-value-container">
        <div className="header">
          <div className="h2">Average Order Value</div>
          <div className='dropdown-wrap'>
            {/*<div className="h5">Date Range</div>*/}
            <div className="select-container">
              <WppSelect
                onWppChange={handleChange}
                placeholder={selectedYear}
                value={selectedYear}
              >
                {years.map((year) => (
                  <WppListItem value={year} key={year}>
                    <p slot="label">{year}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
          </div>
        </div>
        <div className="chart-container">
          <ResponsiveBar
            data={data}
            keys={['value']}
            indexBy="x"
            colors="#C880B2"
            margin={{ top: 30, right: 0, bottom: 40, left: 0 }}
            padding={0.2}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12, // Label text size
                    fill: '#444444',
                  },
                },
              },
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              renderTick: ({ textAnchor, textBaseline, value, x, y }) => {
                // ticks and labels below the bars
                const name = value.split(': ');
                return (
                  <g transform={`translate(${x},${y})`}>
                    <line x1={0} y1={0} x2={0} y2={5} stroke="black" />
                    <text
                      className="axis-label"
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                    >
                      <tspan x={0} dy={20}>
                        {name[0]}
                        {name[1] ? ':' : null}
                      </tspan>
                      {name[1] ? <tspan x={0} dy={15}>{name[1]}</tspan> : null}
                    </text>
                  </g>
                );
              },
            }}
            axisLeft={null}
            enableGridY={true}
            gridYValues={[0, 0.01]}
            enableLabel={false}
            layers={[
              'grid',
              'axes',
              'bars',
              'markers',
              'legends',
              'annotations',
              ({ bars }) => {
                return (
                  // labels above the bars
                  <g>
                    {bars.map(({ width, height, x, y, data }) => {
                      return (
                        <text
                          transform={`translate(${width / 2 + x - 10}, ${y - 15})`}
                          className="bar-label"
                        >
                          {`\u20AC${data.value ?? ''}`}
                        </text>
                      );
                    })}
                  </g>
                );
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default AverageOrderValue;
