import React, { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { ResponsivePie } from '@nivo/pie'
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DataGridPro,
  useGridApiContext,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import { hackGrid, hackGridDetail, SortedDescendingIcon, SortedAscendingIcon, UnsortedIcon} from '../../utils'
import '../../../../stylesheets/Explorer.css';
import { cardContentMargin } from '../../styles'

import { NetSalesByCategoryData } from '../../../../data/NivoData';
import './styles/NetSalesByCategory.css';

function DetailPanelContent({ row: rowProp }) {
  const columns = [
    { field: 'spacer', width: 70 },
    { field: 'id', 
      width: 430,
      renderCell: (params) => (
        <Typography sx={{ fontSize: '14px' }}>{params.value}</Typography>
      )
    },
    { field: 'value',
      align: 'right',
      type: 'number',
      renderCell: (params) => (
        <span style={{ backgroundColor: '#E7EAEE', padding: '3px 8px', borderRadius: '4px' }}>
          <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>{params.value}%</Typography>
        </span>
      )
    },
  ];

  return (
    <div style={{ marginTop: '-40px' }}>
      <DataGridPro
        density="compact"
        autoHeight
        columns={columns}
        rows={rowProp.data}
        rowHeight={70}
        sx={{
          flex: 1,
          '.MuiDataGrid-columnHeader': {
            display: 'none',
          },
          '&, [class^=MuiDataGrid-main]': { borderBottom: '1px solid rgba(224, 224, 224, 1)' },
        }}
        getCellClassName={() => {return 'h5'}}
        hideFooter
      />
    </div>
  );
}

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector,
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 0 : -90}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

const masterColumns: GridColDef[] = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => (
      <CustomDetailPanelToggle id={params.id} value={params.value} />
    ),
  },
  { field: 'id',
    headerName: 'Category',
    width: 430,
    renderCell: (params) => (
      <Typography sx={{ fontSize: '18px' }}>{params.value}</Typography>
    )
  },
  { field: 'value',
    headerName: '',
    align: 'right',
    sortable: 'false',
    renderCell: (params) => (
      <span style={{ backgroundColor: '#FDEAE2', padding: '3px 8px', borderRadius: '4px' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#C2410C' }}>{params.value}%</Typography>
      </span>
    )
  },
];

const NetSalesByCategory = () => {

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [chartData, setChartData] = useState([]);
  const [rows, setRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  // const [fullyExpanded, setFullyExpanded] = useState(false);

  const getDetailPanelContent = useCallback(({ row }) => <DetailPanelContent row={row} />, []);
  const getDetailPanelHeight = useCallback(() => 'auto', []);

  useEffect(() => {
    hackGrid();
  }, []);
  useEffect(() => {
    hackGridDetail();
  }, [expandedRows]);

  useEffect(() => {
    setYears(NetSalesByCategoryData.map(item => item.year));
  }, []);

  useEffect(() => {
    const selectedData = NetSalesByCategoryData.filter((a) => a.year === selectedYear);

    if (selectedData.length) {
      setChartData(selectedData[0].data);
      setRows(selectedData[0].data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear])

  const handleChange = (event) => {
    setSelectedYear(event.detail.value);
  }

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setExpandedRows(newIds);
    },
    [],
  );

  // const expandAll = () => {
  //   if (fullyExpanded) {
  //     setFullyExpanded(false);
  //     setExpandedRows([]);
  //   } else {
  //     setFullyExpanded(true);
  //     setExpandedRows(chartData.map((item) => item.id));
  //   }
  // };

  return (
    <div className='net-sales-by-category-container'>
      <div className='net-sales-header'>
        <div>
          <div className='h2'>
            Net Sales By Category
          </div>
          <div className='h5'>
            eCommerce sales by category
          </div>
        </div>
        <div>
          {/*<div className='h5' style={{ marginTop: '8px' }}>*/}
          {/*  Date Range*/}
          {/*</div>*/}
          <div>
            <WppSelect
              onWppChange={handleChange}
              placeholder={selectedYear}
              value={selectedYear}
            >
              {
                years.map(year => (
                  <WppListItem value={year} key={year}>
                    <p slot="label">{year}</p>
                  </WppListItem>
                ))
              }
            </WppSelect>
          </div>
        </div>
      </div>

      <div className='net-sales-chart-container'>
        <ResponsivePie
          data={chartData}
          margin={{ top: 50, right: 40, bottom: 50, left: 40 }}
          innerRadius={0.6}
          activeOuterRadiusOffset={15}
          colors={{ scheme: 'nivo' }}
          animate={true}
          arcLinkLabel={(x) =>{
            let [first, ...rest] = x.label.split('&')
            return  <>
                <tspan x="0" dy={rest.length ? "-0.6em": "0"}>{first}</tspan>
                {rest.length ? <tspan x={x.arc.angle < 2 ? -x.arc.angle - 2.5 : x.arc.angle - 2} dy="1em">{`& ${rest}`}</tspan> : null}
              </>
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextOffset={8}
          arcLinkLabelsTextColor="#4f4f4f"
          arcLinkLabelsOffset={6}
          arcLinkLabelsDiagonalLength={25}
          arcLinkLabelsStraightLength={15}
          arcLinkLabelsColor="#707070"
          arcLabelsSkipAngle={10}
          arcLabel={(x) => (`${x.value}%`)}
          arcLabelsTextColor="#ffffff"
          legends={[]}
        />
      </div>
      {/* Tabular Data */}
      <div className='tabular-data-container'>
        <DataGridPro
          columns={masterColumns}
          rows={rows}
          rowHeight={50}
          rowThreshold={0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          hideFooter={true}
          detailPanelExpandedRowIds={expandedRows}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          disableColumnMenu={true}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textTransform: 'none',
              fontWeight: '800',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&, [class^=MuiDataGrid-footerContainer]': {
              borderTop: 'none'
            },
            "& .MuiDataGrid-iconButtonContainer": {
              marginLeft: '-300px',
              visibility: 'visible !important',
              width: 'auto !important',
            },
          }}
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
          }}
        />
      </div>
    </div>
  )
}
export default NetSalesByCategory
