import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import CommentIcon from '@mui/icons-material/Comment';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';

export default function BasicTooltip(props) {

  const { title } = props;

  return (
    <Tooltip title={title}>
      {/*<IconButton>*/}
      {/*<span style={{padding: '8px', background: '#f1f1f1', width: '16px', height: '16px', borderRadius: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>*/}
        <InfoOutlinedIcon style={{color: 'rgb(102, 102, 102)', width: '18px'}}/>
      {/*</span>*/}
      {/*</IconButton>*/}
    </Tooltip>
  );
}
