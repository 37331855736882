import React, { useEffect, useState } from 'react';
import HomeHero from "../components/HomeHero";
import Cards from "../components/Cards";
import {insightCardData } from "../data/DummyData";
import Header from "../components/Header";
import ReactGA from "react-ga4";
import SideBar from "../components/SideBar";
import '../stylesheets/HomePage.css';

function HomePage(props) {

  const { title, userName } = props;
  const [showHome, setShowHome] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });
    setTimeout(() => {
      setShowHome(true)
    }, 10)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <div className={showHome ? 'fade-in' : 'invisible'}>
        <SideBar/>
        <div className={'page-wrapper'}>
          <Header
            title="[Fusion Insights]"
            userName={userName}
          />
          <div className="home col-1-1">
            <HomeHero
              userName={userName}
            />
          </div>
          <div className="home-wrapper">
            <div className="home-cards col-1-1">
              <Cards
                data={insightCardData[0].media}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
