import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    OutlinedInput,
    InputLabel,
    MenuItem,
    FormControl,
    ListItemText,
    Select,
    Checkbox
 } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = {
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
};

type DropMultiSelectByIDType = {
    title: string,
    items: Array<string>,
    width: number,
    outputSelections: (selections: Array<string>, type: string) => void,
    filterColumns: boolean,
    type: string,
    selections?: Array<string>,
}

export default function DropMultiSelectByID(props: DropMultiSelectByIDType) {
    
    const { title, items, width, outputSelections, filterColumns, type, selections } = props;
    
    const [dropItems, setDropItems] = useState([]);
    const isAllSelected = items.length > 0 && dropItems.length === items.length;

    useEffect(() => {
        if (selections?.length) {
            setDropItems(selections);
        } else {
            setDropItems([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections])
    
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        let filter = value;
        if (value[value.length - 1] === "all") {

            const allItems = items.map(item => item.id);
            setDropItems(dropItems.length === items.length ? [] : allItems);
            
            if (dropItems.length === items.length) {
                filter = [-1];
            } else {
                filter = allItems
            }
        } else {
            setDropItems(
                typeof value === 'string' ? value.split(',') : value,
            );

            if (value.length === 0) {
                filter = [-1]
            }
        }

        if (filterColumns === true) {
            setTimeout(() => {
                outputSelections(filter, type)
            }, 200)
        }
    };

    const handleClose = (event) => {
        // console.log('close')
    }

    return (
        <div>
            <FormControl sx={{ m: 3, width: width, margin: 0 }}>
                <InputLabel id="demo-multiple-checkbox-label">{title}</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={dropItems}
                    onChange={handleChange}
                    onClose={handleClose}
                    input={<OutlinedInput label={title} />}
                    renderValue={(selections) => {
                        const selected = [];
                        _.forEach(selections, (selection) => {
                            selected.push(items.find(o => o.id === selection).name);
                        })
                        return selected.sort().join(', ')
                    }}
                    MenuProps={MenuProps}
                >
                    {/* <MenuItem
                        value="all"
                        classes={{
                            root: isAllSelected ? useStyles.selectedAll : ""
                        }}
                        >
                        <Checkbox
                            classes={{ indeterminate: useStyles.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                dropItems.length > 0 && dropItems.length < items.length
                            }
                        />
                        <ListItemText
                            classes={{ primary: useStyles.selectAllText }}
                            primary="Select All"
                        />
                    </MenuItem> */}
                    
                    {_.orderBy(items, ['name']).map((item) => (
                        <MenuItem key={item.name} value={item.id}>
                            <Checkbox checked={dropItems.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
