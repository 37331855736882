import React, { useEffect } from "react";
import ReactGA from "react-ga4";

function InsightDspPage(props) {

  const { title } = props;

  useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <div className={'col-1-1 margin-t-96 flex-direction-column flex-center-all'}>
        <div className={'col-1-2 margin-t-96 flex-direction-column'}>
          <h1>DSP Capabilities</h1>
          <p>Coming soon ;)</p>
        </div>
      </div>
    </>
  );
}

export default InsightDspPage;
