export const columns = [
  {
    field: 'area',
    headerName: 'Evaluation Area',
    width: 260,
  },
  {
    field: 'pacvue',
    headerName: 'Pacvue',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src="https://global-uploads.webflow.com/5f15d340c06f693ca3c94281/5f2339a8737ec733d8eb5070_logo_pacvue_main.svg"
          style={{width: '80px', height: 'auto'}}
        />
        <div className={'status-flag'} style={{backgroundColor: '#8cff8f'}}>Preferred</div>
      </div>
    )
  },
  {
    field: 'skai',
    headerName: 'Skai',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src="data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 323.53 146.01'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M37.1 144.49H.76v-21.58c9.27 14 18.73 20.07 29 20.07 9.65 0 17.6-5.11 17.6-13.63C47.32 108.53 0 107.58 0 77.11c0-16.28 13.44-27.25 33.88-27.25h30.47v21.57c-10.6-15-19.11-20.06-29.15-20.06-8.89 0-14.57 4.35-14.57 11.55 0 23.65 51.29 18.54 51.29 54.32 0 17.41-12.11 27.25-34.82 27.25zM89 30.74L115.23.25c.35-.41.63-.3.63.23v144H89zm55.08 55.65l43 58.1h-33.36L125 104.93zm35.58-36.53L149.17 79c2.46-14-5.68-25.36-20.63-29.14zM188.35 119.32c0-36.34 60.57-25.74 60.57-53.38 0-8.7-6.25-14.57-15.52-14.57-9.84 0-20.06 7-29.15 20.06V49.86h34.64c23.11 0 36.72 12.14 36.72 32.74v61.89h-26.88V75C241.35 90.36 216 92.25 216 115.53c0 11.93 6.62 19.5 17 19.5a18.3 18.3 0 0 0 13.25-5.49c-5.49 11-14.76 16.47-27.63 16.47-18.34-.01-30.27-10.41-30.27-26.69zM319.56 144.49V49.86h-26.88v94.63zM323.42 19.42a16 16 0 0 0-18-14 15.65 15.65 0 0 0-12.67 8.85.36.36 0 0 0 .51.46 16 16 0 0 1 8.6-2.49 16.3 16.3 0 0 1 16.26 16.25 16.7 16.7 0 0 1-.68 4.71.35.35 0 0 0 .57.36 16.39 16.39 0 0 0 5.41-14.14z'/%3E%3C/g%3E%3C/svg%3E"
          style={{width: '40px', height: 'auto'}}
        />
        <div className={'status-flag'} style={{backgroundColor: '#8cff8f'}}>Preferred</div>
      </div>
    )
  },
  {
    field: 'epsilo',
    headerName: 'Epsilo',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={'https://assets.website-files.com/61cee65363a6d118502e331c/61cee65363a6d1c3b52e337d_LOGO%20EPSILO%20LIGHT%20BG%20VERSION%201.png'}
          style={{width: '56px', height: 'auto'}}
        />
        <div className={'status-flag'} style={{backgroundColor: '#8cff8f'}}>Preferred</div>
      </div>
    )
  },
  {
    field: 'intentwise',
    headerName: 'Intentwise',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={'https://assets-global.website-files.com/608431e7927a12b3efd486f3/60e47f4515c4d8458c2e8ae0_intentwise%20light%20logo.svg'}
          style={{width: '92px', height: 'auto'}}
        />
        <div className={'status-flag'} style={{backgroundColor: '#d2ff69'}}>Controlled Testing</div>
      </div>
    )
  },
  {
    field: 'commerce_iq',
    headerName: 'Commerce IQ',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={'https://commerceiq.ai/wp-content/themes/ciq2/assets/img/logo.svg'}
          style={{width: '110px', height: 'auto'}}
        />
        <div className={'status-flag'} style={{backgroundColor: '#d2ff69'}}>Controlled Testing</div>
      </div>
    )
  },
  {
    field: 'perpetua',
    headerName: 'Perpetua',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={'https://lever-client-logos.s3.us-west-2.amazonaws.com/07fffee5-0149-485c-a113-38a276fececa-1637075800335.png'}
          style={{width: '110px', height: 'auto'}}
        />
        <div className={'status-flag'} style={{backgroundColor: '#ffb52b'}}>Unapproved</div>
      </div>
    )
  },
];

export const rows = [
  {
    id: 1,
    area: 'Commercials',
    pacvue_title: 'Self Serve Avg',
    pacvue_ssa: '1.75%',
    skai_title: 'Self Serve Avg',
    skai_ssa: '1.75%',
    epsilo_title: 'Self Serve Avg / Store',
    epsilo_ssa: '$300',
    intentwise_title: 'Self Serve Avg',
    intentwise_ssa: '2.00%',
    commerce_iq_title: 'Self Serve Avg',
    commerce_iq_ssa: '2.00%',
    perpetua_title: 'Self Serve Avg',
    perpetua_ssa: '1.5%',
  },
  {
    id: 2,
    area: 'Market and Retails Coverage',
    pacvue_overall: 34,
    pacvue_latam: 24,
    pacvue_apac: 34,
    pacvue_emea: 12,
    pacvue_na: 44,
    skai_overall: 42,
    skai_latam: 12,
    skai_apac: 49,
    skai_emea: 23,
    skai_na: 16,
    epsilo_overall: 23,
    epsilo_latam: 34,
    epsilo_apac: 12,
    epsilo_emea: 49,
    epsilo_na: 23,
    intentwise_overall: 23,
    intentwise_latam: 34,
    intentwise_apac: 12,
    intentwise_emea: 49,
    intentwise_na: 23,
    commerce_iq_overall: 42,
    commerce_iq_latam: 12,
    commerce_iq_apac: 49,
    commerce_iq_emea: 23,
    commerce_iq_na: 16,
    perpetua_overall: 42,
    perpetua_latam: 12,
    perpetua_apac: 49,
    perpetua_emea: 23,
    perpetua_na: 16,
  },
  {
    id: 3,
    area: 'Search Capabilities',
    pacvue_overall: 55,
    pacvue_bid_rules: 23,
    pacvue_optimization: 34,
    skai_overall: 23,
    skai_bid_rules: 12,
    skai_optimization: 49,
    epsilo_overall: 12,
    epsilo_bid_rules: 49,
    epsilo_optimization: 23,
    intentwise_overall: 44,
    intentwise_bid_rules: 65,
    intentwise_optimization: 36,
    commerce_iq_overall: 83,
    commerce_iq_bid_rules: 22,
    commerce_iq_optimization: 67,
    perpetua_overall: 25,
    perpetua_bid_rules: 88,
    perpetua_optimization: 32,
  },
  {
    id: 4,
    area: 'DSP Capabilities',
    pacvue_overall: 55,
    pacvue_bid_rules: 23,
    pacvue_optimization: 34,
    skai_overall: 23,
    skai_bid_rules: 12,
    skai_optimization: 49,
    epsilo_overall: 12,
    epsilo_bid_rules: 49,
    epsilo_optimization: 23,
    intentwise_overall: 43,
    intentwise_bid_rules: 49,
    intentwise_optimization: 23,
    commerce_iq_overall: 76,
    commerce_iq_bid_rules: 49,
    commerce_iq_optimization: 23,
    perpetua_overall: 12,
    perpetua_bid_rules: 49,
    perpetua_optimization: 23,
  },
  {
    id: 5,
    area: 'Reporting',
    pacvue_overall: 33,
    skai_overall: 55,
    epsilo_overall: 76,
    intentwise_overall: 36,
    commerce_iq_overall: 76,
    perpetua_overall: 56,
  },
  {
    id: 6,
    area: 'Models & Methodologies',
    pacvue_title: 'Rules Based Automation',
    pacvue_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    skai_title: 'Rules Based Automation',
    skai_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    epsilo_title: 'Rules Based Automation',
    epsilo_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    intentwise_title: 'Rules Based Automation',
    intentwise_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    commerce_iq_title: 'Rules Based Automation',
    commerce_iq_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    perpetua_title: 'Rules Based Automation',
    perpetua_description: 'Advanced SOV scraping, up to 12x/day and weighting',
  },
  {
    id: 7,
    area: 'Platform UX',
    pacvue_overall: 65,
    skai_overall: 21,
    epsilo_overall: 44,
    intentwise_overall: 54,
    commerce_iq_overall: 89,
    perpetua_overall: 13,
  },
  {
    id: 8,
    area: 'X Factor Differentiators',
    pacvue_description: 'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    skai_description: 'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    epsilo_description: 'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    intentwise_description: 'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    commerce_iq_description: 'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    perpetua_description: 'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
  },
];
