import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { SeaData } from '../../../../data/NivoData';
import { SeoData } from '../../../../data/NivoData';
import { ResponsiveLine } from '@nivo/line';
import { cardContentMargin } from '../../styles'

dayjs.extend(isSameOrAfter);

const INITIALIZE_DATE_INTERVAL_VALUE = 12;

const VisibilityScore = () => {

  const [dateRange, setDateRange] = useState(INITIALIZE_DATE_INTERVAL_VALUE);
  const [seaData, setSeaData] = useState([]);
  const [seoData, setSeoData] = useState([]);

  useEffect(() => {
    filter(dateRange);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  const handleChange = (event) => {
    setDateRange(event.detail.value);
  }

  const filter = (range) => {
    const SEA = [];
    SEA.push(
      { 
        "id": 'SEA',
        "data": SeaData[0].data
                .filter(item => dayjs(item.x).isSameOrAfter(dayjs().subtract(range, 'month')))
                .map(a =>  ({ 'x': dayjs(a.x).format('MM/YYYY'), 'y': a.y })),
      }
    );
    setSeaData(SEA);

    const SEO = [];
    SEO.push(
      { 
        "id": 'SEO',
        "data": SeoData[0].data
                .filter(item => dayjs(item.x).isSameOrAfter(dayjs().subtract(range, 'month')))
                .map(a =>  ({ 'x': dayjs(a.x).format('MM/YYYY'), 'y': a.y })),
      }
    );
    setSeoData(SEO);
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: cardContentMargin }}>
          <div className='h2'>
            SEA Visibility Score
          </div>
          <div className='vis-dropdown-wrap'>
            {/*<div className='h5'>*/}
            {/*  Date Range*/}
            {/*</div>*/}
            <div style={{width: '150px', marginTop: '8px' }}>
              <WppSelect
                onWppChange={handleChange}
                placeholder="last 12 months"
                value={dateRange}
              >
                <WppListItem value="12" key={12}>
                  <p slot="label">last 12 months</p>
                </WppListItem>
                <WppListItem value="24" key={24}>
                  <p slot="label">last 24 months</p>
                </WppListItem>
              </WppSelect>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', height:'275px', marginTop: '10px' }}>
          <ResponsiveLine
            data={seaData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            colors={'#C880B2'}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: 'transportation',
                // legendOffset: 14,
                // legendPosition: 'middle'
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: 4,
                // legend: 'count',
                // legendOffset: -40,
                // legendPosition: 'middle'
              }}
            enableGridX={true}
            enableGridY={true}
            gridXValues={[0, .01]}
            gridYValues={[0, .01]}
            lineWidth={3}
            enablePoints={false}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[]}
          />
        </div>
      </div>
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: cardContentMargin }}>
          <div className='h2'>
              SEO Visibility Score
          </div>
          <div className='vis-dropdown-wrap'>
            {/*<div className='h5'>*/}
            {/*  Date Range*/}
            {/*</div>*/}
            <div style={{width: '150px', marginTop: '8px' }}>
              <WppSelect
                onWppChange={handleChange}
                placeholder="last 12 months"
                value={dateRange}
              >
                <WppListItem value="12" key={12}>
                  <p slot="label">last 12 months</p>
                </WppListItem>
                <WppListItem value="24" key={24}>
                  <p slot="label">last 24 months</p>
                </WppListItem>
              </WppSelect>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', height:'275px', marginTop: '10px' }}>
          <ResponsiveLine
            data={seoData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            colors={'#C880B2'}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: 'transportation',
                // legendOffset: 14,
                // legendPosition: 'middle'
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: 4,
                // legend: 'count',
                // legendOffset: -40,
                // legendPosition: 'middle'
            }}
            enableGridX={true}
            enableGridY={true}
            gridXValues={[0, .01]}
            gridYValues={[0, .01]}
            lineWidth={3}
            enablePoints={false}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[]}
          />
        </div>
      </div>
    </>
  )
}
export default VisibilityScore;
