import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { hackGrid, SortedDescendingIcon, SortedAscendingIcon, UnsortedIcon} from '../../utils'
import { cardContentMargin } from '../../styles';
import './styles/CompetitiveLandscape.css';

import { CompetitiveLandscapeData } from '../../../../data/NivoData';

const columnDefs: GridColDef[] = [
  { field: 'Online Store', width: 200 },
  { field: 'Net Sales', width: 170 },
  { field: 'Market Share', width: 180, align: 'right', hideSortIcons: true, sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => (
      <div style={{ fontWeight: '800', marginLeft: 'auto', marginRight: '0' }}>
        {'Market Share '}
      </div>
    ),
 },
];

const CompetitiveLandscape = () => {

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setYears([...new Set(CompetitiveLandscapeData.map(item => item.year))]);
  }, [])

  useEffect(() => {
    hackGrid();
  })

  useEffect(() => {
    const selectedData = CompetitiveLandscapeData.filter((a) => a.year === selectedYear);
    if (selectedData.length) {
      setRows(selectedData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear])

  const handleChange = (event) => {
    setSelectedYear(event.detail.value);
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className={'competitive-landscape-header'}>
        <div>
          <div className='h2'>
            Competitive Landscape
          </div>
          <div className='h5' style={{ marginTop: '4px' }}>
            Top online stores: Food & Personal Care in France
          </div>
        </div>
        <div className='dropdown-wrap'>
          {/*<div className='h5' style={{ marginTop: '8px' }}>*/}
          {/*  Date Range*/}
          {/*</div>*/}
          <div className={'select-container'}>
            <WppSelect
              onWppChange={handleChange}
              placeholder={selectedYear}
              value={selectedYear}
            >
              {
                years.map(year => (
                  <WppListItem value={year} key={year}>
                    <p slot="label">{year}</p>
                  </WppListItem>
                ))
              }
            </WppSelect>
          </div>
        </div>
      </div>

      <div className='data-grid-container'>
        <DataGridPro
          getRowId={(row) => row.year.toString() + row["Online Store"]}
          columns={columnDefs}
          rows={rows}
          rowHeight={50}
          disableColumnMenu={true}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textTransform: 'none',
              fontWeight: '800',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-iconButtonContainer': {
              marginLeft: '-70px',
              visibility: 'visible !important',
              width: 'auto !important',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              margin: '0'
            },
          }}
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
          }}
          hideFooter={true}
        />
      </div>
    </div>
  )
}

export default CompetitiveLandscape;