import React from 'react';
import {
  Dialog,
  IconButton,
}  from "@mui/material";
import Revenue from './Explorer/Revenue';
import NetSalesByCategory from './Explorer/NetSalesByCategory';
import AverageOrderValue from './Explorer/AverageOrderValue';
import AverageConversionRate from './Explorer/AverageConversionRate';
import VisibilityScore from './Explorer/VisibilityScore';
import CloseIcon from '@mui/icons-material/Close';
import CompetitiveLandscape from './Explorer/CompetitiveLandscape';
import TopLevelStats from './Explorer/TopLevelStats';
import SocialMediaMetrics from './Explorer/SocialMediaMetrics';
import TrafficAnalytics from './Explorer/TrafficAnalytics';
import '../../../stylesheets/Explorer.css';


const Explorer = (props) => {

  const { card, isExplorerOpen, setIsExplorerOpen } = props;

  const handleClose = () => {
    setIsExplorerOpen(false);
  };

  return (
  <Dialog
    fullWidth
    maxWidth={'100%'}
    scroll={'paper'}
    open={isExplorerOpen}
    onClose={handleClose}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{justifySelf: 'flexEnd', position: 'absolute', top: '24px', right: '24px', color: '#333', zIndex: '100'}}
    >
      <CloseIcon />
    </IconButton>

    <div className={'exp-market row flex-center-all'}>
      <span className={'exp-market-name'}>Market</span>
      <span className={'exp-market-code'}>{card.marketCode}</span>
    </div>
    <div className={'row flex-direction-column flex-align-start exp-head-wrap'}>
      <div className={'row flex-direction-row flex-center-all'}>
        <img alt="partner-logo" src={`https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/${card.retailerId}.png`} style={{width: "180px", height: "auto", margin: '0 0 0 24px'}}/>
        {/*<div style={{margin: '0 0 0 24px'}}>{card.retailerName}</div>*/}
        {/*<div className={'exp-market'}>FR</div>*/}
      </div>
      <TopLevelStats />
    </div>

    <div className={'row flex-direction-column flex-center-all bg-light-gray exp-det-wrap'}>
      <div className={'col-1-1'}>
        <div className={'col-1-1 flex-direction-column flex-center-all exp-box'}>
          <Revenue />
        </div>
      </div>
      <div className={'col-1-1'}>
        <div className={'col-1-2 flex-direction-column flex-center-all exp-box'}>
          <NetSalesByCategory />
        </div>
        <div className={'col-1-2 flex-direction-column flex-center-all padding-16'}>
          <div className={'col-1-1 flex-direction-column flex-center-all exp-box-within padding-48'}>
            <CompetitiveLandscape/>
          </div>
          <div className={'col-1-1 flex-direction-column flex-center-all exp-box-within padding-48'}>
            <AverageOrderValue />
          </div>
          <div className={'col-1-1 flex-direction-column flex-center-all exp-box-within padding-48'}>
            <AverageConversionRate />
          </div>
        </div>
      </div>
      <div className={'col-1-1'}>
        <div className={'col-1-1 flex-direction-column flex-center-all exp-box'}>
          <VisibilityScore />
        </div>
      </div>
      <div className={'col-1-1'}>
        <div className={'col-1-2 flex-direction-column flex-center-all exp-box'}>
          <TrafficAnalytics />
        </div>
        <div className={'col-1-2 flex-direction-column flex-center-all exp-box'}>
          <SocialMediaMetrics />
        </div>
      </div>
    </div>

  </Dialog>
  )
}

export default Explorer;