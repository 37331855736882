import React, { useState, useEffect, useRef } from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import HomePage from "./pages/HomePage";
import InsightDspPage from "./pages/InsightDspPage";
import InsightBidPage from "./pages/InsightBidPage";
import PartnerDetailsPage from "./pages/PartnerDetailsPage";
import AuthPage from "./pages/AuthPage";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import ReactGA from 'react-ga4'
import InsightsRtmV2Page from "./pages/InsightsRtm/InsightsRtmPage";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "./components/AuthConfig";
import './App.css';
import './stylesheets/Grid.css';
import './stylesheets/Typography.css'
import '@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css';

const trackingId = 'G-KHDCWWGZX9';
ReactGA.initialize(trackingId);
function App() {
  const [userName, setUserName] = useState('...');
  const [userEmail, setUserEmail] = useState('');


  const isProd = useRef(null);

  const { instance, accounts } = useMsal();
  
  useEffect(() => {
    if(isProd.current === null) {
      if (window.location.hostname === 'localhost') {
        isProd.current = false;
      } else {
        isProd.current = true;
      }
    }
  }, []);
  
  useEffect(() => {
    if (accounts.length) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          if (userName !== response.account.name) {
            setUserName(response.account.name);
            setUserEmail(response.account.username);
          }

          console.log('userName : ', response.account.name);
          console.log('userEmail : ', response.account.username);
        })
        .catch(async (e) => {
          throw e;
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <>
            <UnauthenticatedTemplate>
              <Navigate replace to={"/auth"} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Navigate replace to={"/home"} />
            </AuthenticatedTemplate>
          </>
        }/>
        <Route path="/auth" element={
          <AuthPage
            title="Insights 2.0 Auth"
          />
        }/>
        <Route path="/home" element={
          <>
            {isProd.current !== null && isProd.current ?
              <AuthenticatedTemplate>
                <HomePage
                  title="Insights 2.0 Home"
                  userName={userName}
                />
              </AuthenticatedTemplate>
            :
              <HomePage
                title="Insights 2.0 Home"
                userName={'Teo Milicevic'}
              />
            }
            {isProd.current !== null && isProd.current ?
              <UnauthenticatedTemplate>
                <Navigate replace to={"/"} />
              </UnauthenticatedTemplate>
              :
              null
            }
          </>
        }/>
        <Route path="/insight/rtmv2" element={
          <>
            {isProd.current !== null && isProd.current ?
              <AuthenticatedTemplate>
                <InsightsRtmV2Page
                  title="Retail Media Capabilities V2"
                  userName={userName}
                  userEmail={userEmail}
                />
              </AuthenticatedTemplate>
              :
              <InsightsRtmV2Page
                title="Retail Media Capabilities V2"
                userName={'Teo Milicevic'}
                userEmail={'jack.reidy@groupm.com'}
              />
            }
            {isProd.current !== null && isProd.current ?
              <UnauthenticatedTemplate>
                <Navigate replace to={"/"} />
              </UnauthenticatedTemplate>
              :
              null
            }
          </>
        }/>
        <Route path="/insight/bid" element={
          <>
          {isProd.current !== null && isProd.current ?
              <AuthenticatedTemplate>
                <InsightBidPage
                  title="Bid Management Capabilities"
                  trackingId={trackingId}
                  userName={userName}
                  setUserName={setUserName}
                  setUserEmail={setUserEmail}
                />
              </AuthenticatedTemplate>
            :
              <InsightBidPage
                title="Bid Management Capabilities"
                trackingId={trackingId}
                userName={'Dev'}
                setUserName={setUserName}
                setUserEmail={setUserEmail}
              />
            }
          </>
        }/>
        <Route path="/insight/dsp" element={
          <>
              <InsightDspPage
                title="DSP Capabilities"
                trackingId={trackingId}
              />
          </>
        }/>
        <Route path="/partner-details" element={
          <PartnerDetailsPage
            title="Partner Details"
            trackingId={trackingId}
          />
        }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
