console.log(window.location.hostname + '/home');
export const msalConfig = {
  auth: {
    clientId: "787f340f-f5b3-4fb5-9064-a6acc08f7dca",
    authority: "https://login.microsoftonline.com/86cbe1bb-213f-4271-b174-bd59d03c87a3",
    redirectUri: 'https://' + window.location.hostname + '/home'
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
};
