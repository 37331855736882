import _ from 'lodash';
import React, { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SuperSearch(props) {

  const { 
    setLiveColumns,
    liveColumnsOg,
    setCardData,
    cardDataOg,
    superSearchValues,
    setSuperSearchValues,
    newRetailers,
  } = props;
  
  useEffect(() => {
    if (newRetailers.length) {
      const newRetailerSearchItems = [];
      _.forEach(newRetailers, retailer => {
        const item = _.find(liveColumnsOg, ['field', retailer]);
        if(item) {
          newRetailerSearchItems.push(item);
        }
      })

      setSuperSearchValues(newRetailerSearchItems);

      let tempColumns = []
      let tempCardsRet = []
      let tempCardsMar = []

      tempColumns = [liveColumnsOg[0].field]
      tempColumns.push(liveColumnsOg[1].field)
      tempColumns.push(liveColumnsOg[2].field)

      newRetailers.forEach((retailer, index) => {
        tempColumns.push(retailer)
        tempCardsRet.push(retailer.split('|')[0])
        tempCardsMar.push(retailer.split('|')[1])
      })

      const filteredLiveColumnsOg = liveColumnsOg.filter(e => tempColumns.includes(e.field));
      const filteredCardDataOg = cardDataOg.filter(e => tempCardsRet.includes(e.retailerId) && tempCardsMar.includes(e.marketId));

      if (filteredLiveColumnsOg.length === 3 || filteredCardDataOg.length === 0) return;

      setLiveColumns(filteredLiveColumnsOg);
      setCardData(filteredCardDataOg);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveColumnsOg])

  const handleChange = (event, selectedOps, reason, option) => {

    setSuperSearchValues(selectedOps)
    let tempColumns = []
    let tempCardsRet = []
    let tempCardsMar = []

    if (reason === 'clear') {
      setLiveColumns(liveColumnsOg)
      setCardData(cardDataOg)
    }
    else {
      tempColumns = [liveColumnsOg[0].field];
      tempColumns.push([liveColumnsOg[1].field]);
      tempColumns.push(liveColumnsOg[2].field);
      selectedOps.forEach((selectedOp, index) => {
        if (selectedOp?.field) {
          tempColumns.push(selectedOp.field)
          tempCardsRet.push(selectedOp.field.split('|')[0])
          tempCardsMar.push(selectedOp.field.split('|')[1])
        }
      })
      setLiveColumns(liveColumnsOg.filter(e => tempColumns.includes(e.field)))
      setCardData(cardDataOg.filter(e => tempCardsRet.includes(e.retailerId) && tempCardsMar.includes(e.marketId)))
    }

    if (reason === 'removeOption' && tempColumns.length === 3) {
      setLiveColumns(liveColumnsOg)
      setCardData(cardDataOg)
    }
  }

  const onClose = (event, reason) => {
    // console.log(reason)
  }

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={liveColumnsOg.slice(3)}
        value={superSearchValues}
        getOptionLabel={(option) => option && option.headerName.split('|').join(' | ')}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.headerName.split('|').join(' | ')}
          </li>
        )}
        style={{ width: '60%', margin: '0 180px 32px 180px' }}
        renderInput={(params) => (
          <TextField {...params} label="Filter by Retailer(s)" placeholder="Retailer name" variant="standard" />
        )}
        onChange={handleChange}
        onClose={onClose}
      />
    </>
  );
}

