import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import '../stylesheets/HomeHero.css';
import "swiper/css";
import "swiper/css/pagination";

const HomeHero = (props) => {

  const { userName } = props;

  return (
    <div className="home-hero col-1-1">
      <div className="col-1-2 flex-direction-column padding-left96 padding-right64 skew mob-only">
        <h1>Hi <span className="big-blue-header">{userName.split(' ')[0]}</span>, welcome to Fusion Insights</h1>
        <p>Explore the cards below to see commerce intelligence curated by our GroupM staff.</p>
      </div>
      <div className="col-1-2">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          navigation={false}
          pagination={{
            dynamicBullets: false,
            clickable: true
          }}
          autoplay={{
            delay: 8000
          }}
          className="swiper"
        >
          {/*<SwiperSlide>*/}
          {/*  /!*<img src="https://images.unsplash.com/photo-1640340434855-6084b1f4901c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80" alt=""/>*!/*/}
          {/*  <img src={RtmcHero01}/>*/}
          {/*</SwiperSlide>*/}
          {/*<SwiperSlide>*/}
          {/*  <img src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Amazon_icon.svg" alt="" className="logo-partner"/>*/}
          {/*  <Box sx={{ position: 'relative', display: 'inline-flex' }} className='circ-progress'>*/}
          {/*    <CircularProgress variant="determinate" value={66}/>*/}
          {/*    <Box*/}
          {/*      sx={{*/}
          {/*        top: 0,*/}
          {/*        left: 0,*/}
          {/*        bottom: 0,*/}
          {/*        right: 0,*/}
          {/*        position: 'absolute',*/}
          {/*        display: 'flex',*/}
          {/*        alignItems: 'center',*/}
          {/*        justifyContent: 'center',*/}
          {/*      }}*/}
          {/*      className="circ-progress-text"*/}
          {/*    >*/}
          {/*      <Typography*/}
          {/*        variant="caption"*/}
          {/*        component="div"*/}
          {/*        color="text.secondary"*/}
          {/*      >{`${Math.round(66)}%`}</Typography>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*  <span className="circ-description">Overall GroupM TrustScore&trade;</span>*/}
          {/*</SwiperSlide>*/}
          <SwiperSlide>
            <img src="https://images.unsplash.com/photo-1680254321973-f5e235a20c54?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3400&q=80" alt=""/>
            <div className="hero-overlay"/>
            <h4>Did you know that</h4>
            <h3>Global retail media will reach $110 billion in 2022 and will hit $160 billion in annual revenue in five years' time</h3>
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://images.unsplash.com/photo-1680954579569-afbc6e5beea3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1398&q=80" alt=""/>
            <div className="hero-overlay"/>
            <h4>Did you know that</h4>
            <h3>Twenty of the top global ecommerce companies accounted for 67% of global ecommerce sales in 2021</h3>
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://images.unsplash.com/photo-1680868543815-b8666dba60f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80" alt=""/>
            <div className="hero-overlay"/>
            <h4>Did you know that</h4>
            <h3>Advertising is expected to expand 5.9% in 2023 with stronger gains in CTV, retail media, and fast-growing markets like India</h3>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default HomeHero;
