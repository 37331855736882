import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import SignOutButton from "./SignOutButton";
import badgeFusion from "../images/badge-fusion.png";
import '../stylesheets/Header.css';

function Header(props) {

    const { title, userName } = props;

    // const [buttonText, setButtonText] = useState('Admin Mode');
    // const [buttonLink, setButtonLink] = useState('/admin');
    const isAuthenticated = useIsAuthenticated();

    // useEffect(() => {
    //     const view = window.location.href;
    //     if (view.includes('admin')) {
    //         setButtonText('Client Mode');
    //         setButtonLink('/client');
    //     }
    //     else if (view.includes('client')) {
    //         setButtonText('Admin Mode');
    //         setButtonLink('/admin');
    //     }
    // },[]);

    return (
        <div className="header">
            <div className="col-1-5 flex-direction-row flex-align-center flex-justify-start">
                <a href={'/home'} aria-label={'Home'} className="header-badge-fusion show-on-mob">
                    <img src={badgeFusion} alt="logo Fusion" />
                </a>
            </div>
            <div className="col-3-5 flex-align-center">
                <h2>{title}</h2>
            </div>
            <div className="col-1-5 flex-direction-row flex-align-center flex-justify-end padding-right24">
                <span className={'user-name'}>{userName}</span>
                { isAuthenticated ? <SignOutButton/> : null}
            </div>
        </div>
    )
}

export default Header;
