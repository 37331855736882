import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

function PartnerDetailsPage(props) {

  const { title } = props;

  useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });

  },[])

  return (
    <div className="partner-details">
      <h1>{title}</h1>
    </div>
  );
}

export default PartnerDetailsPage;
