import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./AuthConfig";
import Button from "@mui/material/Button";


/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
function SignInButton () {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
      });
    }
  }
  return (
    <Button
      variant="outlined"
      size="large"
      onClick={() => handleLogin("redirect")}
    >SSO Login
    </Button>
  );
}

export default SignInButton;
