import React, { useEffect, useState } from 'react';
import DropMultiSelectByID from "../../../components/DropMultiSelectByID";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SnackbarComponent from './Snackbar';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="confirm-dialog"
    >
    <DialogTitle id="confirm-dialog">{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button
      variant="contained"
      onClick={() => setOpen(false)}
      >
      No
      </Button>
      <Button
      variant="contained"
      onClick={() => {
        setOpen(false);
        onConfirm();
      }}
      >
      Yes
      </Button>
    </DialogActions>
    </Dialog>
  );
  };

export default function FormDialog(props) {

  const { 
    handleDialogClose,
    dialogOpen,
    setDialogOpen,
    formType,
    setNewRetailers,
    scoredOnly,
    setScoredOnly,
    filtersData,
    cardData,
  } = props;

  const [retailersList, setRetailersList] = useState([]);
  const [newRetailerName, setNewRetailerName] = useState('');
  const [marketIds, setMarketIds] = useState([]);
  const [retailerValueEdit, setRetailerValueEdit] = useState({retailerId: '', name: ''});
  const [retailerValues, setRetailerValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [retailersDeleted, setRetailersDeleted] = useState(false);
  const [retailerMarkets, setRetailerMarkets] = useState([]);

  useEffect(() => {
    if (dialogOpen && (formType === 'edit' || formType === 'delete')) {
      setIsLoading(true)
      
      if (formType === 'edit' && scoredOnly) {
        setScoredOnly(false);
      }

      fetch('https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/partners', {
        method: 'GET',
      })
      .then(response => response.json())
      .then(data => {
          setRetailersList(data.retailers);
          setIsLoading(false);
        }
      )
      .catch((error) => {
        setSnackMessage(error.message);
        setSnackOpen(true);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, retailersDeleted])

  const outputSelections = (selections, type) => {
    setMarketIds(selections)
  }

  const addRetailer = () => {
    // {"partner":{"name":"amazon","types":["Media"]},"markets":["JapWY2vzYilgsA96gfIB"]}
    const data = {"partner": {"name": newRetailerName.trim(), "types": ["Media"]}, "markets": marketIds}
    fetch('https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/partners', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
      body: JSON.stringify(data),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
    .then(data => {
      setScoredOnly(false);
      setDialogOpen(false);
      setNewRetailers(data.created);
    })
    .catch((error) => {
      if (error.message === 'HTTP status 409') {
        setSnackMessage(`The retailer '${newRetailerName.trim()}' exists already.`)
      } else {
        setSnackMessage(error.message);
      }
      setSnackOpen(true);
    });
  }

  const updateRetailer = () => {
    // Edit POST /insights/v2/<insight_id>/partners/<retailer_id>
    // {"markets": ["JapWY2vzYilgsA96gfIB"], "types": ["Media"]}
    const data = {"markets": marketIds, "types": ["Media"]};
    fetch(`https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/partners/${retailerValueEdit.retailerId}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
      body: JSON.stringify(data),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
    .then(data => {
      setSnackMessage(`Retailer ${retailerValueEdit.name} updated successfully`)
      setSnackOpen(true);
      setNewRetailers([]);
    })
    .catch((error) => {
      setSnackMessage(error.message);
      setSnackOpen(true);
    });
  }

  const removeRetailer = () => {
    // Remove POST /insights/v2/<insight_id>/partners/delete
    // body - {"partners": ["partnerId1", "partnerId2", ...]}
    const retailersToRemove = retailerValues.map(retailer => retailer.retailerId);
    fetch('https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/partners/delete', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
      body: JSON.stringify({"partners": retailersToRemove}),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
    .then(data => {
      const retailers = retailerValues.map(retailer => retailer.name);
      setSnackMessage(`Retailer(s) ${[retailers.slice(0, -1).join(', '), retailers.slice(-1)[0]].join(retailers.length < 2 ? '' : ' and ')} deleted successfully`)
      setSnackOpen(true);
      setRetailerValues([]);
      setRetailersDeleted(true);
      setNewRetailers([]);
    })
    .catch((error) => {
      setSnackMessage(error.message);
      setSnackOpen(true);
    });
  };
  
  const getMarketsTypes = (retailer) => {
    if (cardData.length && retailer?.name) {
      const retailer_MarketsTypes = cardData.filter(item => item.retailerName === retailer.name)
      const retailer_Markets = retailer_MarketsTypes.map(item => item.marketId)
      setRetailerMarkets(retailer_Markets);
    } else {
      setRetailerMarkets([]);
    }
  };

  const addRetailerDisabled = !newRetailerName || !newRetailerName.trim().length || !marketIds.length || marketIds[0] === -1;
  const updateRetailerDisabled = !retailerValueEdit || !retailerValueEdit.retailerId || !marketIds.length || marketIds[0] === -1;
  const removeRetailerDisabled = !retailerValues.length;

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        {formType === 'add' 
        &&
          <>
            <DialogTitle>Add Retailer</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To add a retailer, please enter a retailer name in the field below. Once you do, please proceed with editing default scores in the next view.
              </DialogContentText>
              <TextField
                id="standard-basic"
                label="Add Retailer"
                variant="standard"
                value={newRetailerName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewRetailerName(event.target.value);
                }}
                inputProps={{ style: { fontSize: "17px" } }}
                sx={{width:'550px', marginTop: '18px'}}
              />
              <div style={{margin: '16px 0'}}>
                <DropMultiSelectByID
                  title={'Markets'}
                  items={filtersData.markets}
                  width={550}
                  outputSelections={outputSelections}
                  filterColumns={true}
                  type={'markets'}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setNewRetailerName('');
                handleDialogClose();
              }}>Cancel</Button>
              <Button disabled={addRetailerDisabled} onClick={addRetailer}>Add Retailer(s)</Button>
            </DialogActions>
          </>
        }
        {formType === 'edit' 
        &&
          <>
            <DialogTitle>Edit Retailer</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To edit a retailer, please select a retailer name in the dropdown and update the markets as necessary.
              </DialogContentText>
              {/* https://medium.com/nerd-for-tech/demystifying-errors-in-mui-autocomplete-part-i-e306f922a906 
                Above explains the intricacies dealing with Autocomplete in the following case
              */}
              <Autocomplete
                id="editRetailerList"
                autoSelect
                options={retailersList.sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)}
                value={retailerValueEdit}
                getOptionLabel={(option) => option.name}
                style={{ width: '100%', margin: '20px 0 32px 0' }}
                renderInput={(params) => (
                  <TextField {...params} label="Edit Retailer" placeholder="Retailer name" variant="standard" />
                )}
                onChange={(event, newValue) => {
                  setRetailerValueEdit(newValue);
                  getMarketsTypes(newValue);
                }}
                isOptionEqualToValue={(option, value) => value.retailerId === null || value.retailerId === "" || option.retailerId === value.retailerId}
              />
              {isLoading && <div style={{'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'position': 'absolute', 'top': '0', 'width': '560px', 'height': '100%', 'background': 'rgba(255, 255, 255, 0.6', 'zIndex': 10000, }}><CircularProgress/></div>}
              <div style={{margin: '16px 0'}}>
                <DropMultiSelectByID
                  title={'Markets'}
                  items={filtersData.markets}
                  width={550}
                  outputSelections={outputSelections}
                  filterColumns={true}
                  type={'markets'}
                  selections={retailerMarkets}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setRetailerValueEdit({retailerId: '', name: ''});
                setRetailerMarkets([]);
                setSnackOpen(false);
                handleDialogClose();
              }}>Cancel</Button>
              <Button disabled={updateRetailerDisabled} onClick={updateRetailer}>Update Retailer</Button>
            </DialogActions>
          </>
        }
        {formType === 'delete'
        &&
          <>
            <DialogTitle>Remove Retailer(s)</DialogTitle>
            <DialogContent sx={{ width: '500px', height: '200px' }}>
              <DialogContentText>
                Please select retailers from the list.
              </DialogContentText>
              {/* https://medium.com/nerd-for-tech/demystifying-errors-in-mui-autocomplete-part-i-e306f922a906 
                Above explains the intricacies dealing with Autocomplete in the following case
              */}
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={retailersList.sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)}
                value={retailerValues}
                onChange={(event, newValue) => {
                  setRetailerValues(newValue);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: '100%', margin: '20px 0 32px 0' }}
                renderInput={(params) => (
                  <TextField {...params} label="Retailer(s) to remove" placeholder="Retailer name" variant="standard" />
                )}
                isOptionEqualToValue={(option, value) => value.retailerId === "" || option.retailerId === value.retailerId}
              />
              {isLoading && <div style={{'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'position': 'absolute', 'top': '0', 'width': '520px', 'height': '100%', 'background': 'rgba(255, 255, 255, 0.6', 'zIndex': 10000, }}><CircularProgress/></div>}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setSnackOpen(false);
                handleDialogClose();
              }}>Cancel</Button>
              <Button disabled={removeRetailerDisabled} onClick={() => setConfirmOpen(true)}>Remove Retailer(s)</Button>
              <ConfirmDialog
                title="Delete?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={removeRetailer}
              >
                Are you sure you want to delete these retailers?
              </ConfirmDialog>
            </DialogActions>
          </>
        }
        <div>
          <SnackbarComponent
            snackOpen={snackOpen}
            setSnackOpen={setSnackOpen}
            message={snackMessage}
          />
        </div>
      </Dialog>
    </div>
  );
}
