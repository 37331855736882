import React, { useState } from 'react';
import Explorer from './Explorer';
import {
  styled,
  Paper,
  Box,
  Typography,
  Grid,
  CircularProgress,
}  from "@mui/material";
import { getColor } from '../utils';

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const SummaryViewCard = (props) => {

  const { index, card } = props;

  const [isExplorerOpen, setIsExplorerOpen] = useState(false);
 
  return (
  <Grid item={true} xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
    <Item sx={{'position': 'relative', height: 'auto', mx: .5, my: 1 }}
        className='circ-progress-card'
        onClick={() => setIsExplorerOpen(true)}
        >
      <Box sx={{height: '75px', overflow: 'hidden', mt: -3 }}>
        <img
          alt="partner-logo"
          style={{width: '112px', height: 'auto'}}
          src={'https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/' + card.retailerId + '.png'}
        />
      </Box>
      <Typography
        sx={{
          position: 'absolute',
          top: '8px',
          left: '8px',
          fontSize: '12px',
          'opacity': .5
        }}>
        {card.retailerName + ' / #' + (index + 1)}
      </Typography>
      <Typography
        sx={{
          position: 'absolute',
          top: '0',
          right: '0',
          fontSize: '14px',
          'opacity': .6,
          py: .5,
          px: 1.5,
          background: '#f1f1f1',
          borderRadius: '0 0 0 8px'
        }}>
        {card.marketCode}
      </Typography>
      <CircularProgress
        variant="determinate"
        sx={{
          position: 'absolute',
          width: '188px',
          left: '10px',
          right: '0',
          top: '174px',
          margin: '0 auto',
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        thickness={5}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          position: 'absolute',
          width: '188px',
          left: '0',
          right: '0',
          top: '186px',
          margin: '0 auto',
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800],
        }}
        thickness={1}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={parseInt(card.score)}
        thickness={5}
        sx={{
          position: 'absolute',
          width: '188px',
          left: '0',
          right: '10px',
          top: '174px',
          margin: '0 auto',
          ".MuiCircularProgress-circle": {color: getColor(card.score / 20)}
        }}
      />
      <Typography
        variant="caption"
        component="div"
        color="text.secondary"
        sx={{
          fontSize: '42px',
          fontWeight: 300,
          mt: 8.5,
          opacity: 0.8,
        }}
      >
        {`${parseInt(card.score)}%`}
      </Typography>
      <div style={{marginTop: '96px'}}>
        {
          card.channels.map((channel, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  borderBottom: '1px solid #f2f2f2',
                  margin: '4px 0 0 0',
                  padding: '0 0 4px 0',
                  boxSizing: 'border-box',
                  minHeight: '25px',
                }}
              >
                <div
                  style={{
                    textAlign: 'left',
                    fontSize: '12px',
                    opacity: 0.6,
                    letterSpacing: '0px',
                    width: '50%',
                  }}
                >
                  {channel.name}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '50%',
                  }}
                >
                  {[...Array(parseInt(channel.score))].map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: getColor(parseInt(channel.score)),
                          width: '16px',
                          height: '16px',
                          margin: '2px',
                          borderRadius: '4px'
                        }}>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
    </Item>
    <Explorer
      card={card}
      isExplorerOpen={isExplorerOpen}
      setIsExplorerOpen={setIsExplorerOpen}
    />
  </Grid>
)
}

export default SummaryViewCard;