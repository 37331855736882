import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import '../stylesheets/Cards.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function Cards(props) {

    const { data } = props;

    const navigate = useNavigate();

    // const [dialogTrigger, setDialogTrigger] = useState(false);
    // const [partnerId, setPartnerId] = useState(0);
    // const [highlightData, setHighlightData] = useState({});
    // const [isHighlightDataLoaded, setIsHighlightDataLoaded] = useState(false);
    // const [insightId, setInsightId] = useState('YpSdg8NNAy9H4pjFHx7P');

    return (
        <>
            {
                <div className="col-1-1 margin-t-42">
                    <Box sx={{flexGrow: 1}}>
                        <Grid container spacing={2}>
                            {data.map((insight, index) => (
                                <Grid xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                                    <Item
                                        className={'insight-card'}
                                        style={{
                                            minHeight: "200px",
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            cursor: 'pointer',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            padding: '16px 32px',
                                            margin: '0 8px'
                                        }}
                                          onClick={() => {
                                              if (insight.path.startsWith('http')) {
                                                  window.location.href = insight.path
                                              }
                                              else {
                                                    navigate(insight.path);
                                              }
                                          }}
                                    >
                                        <div
                                            className={'color-bar'}
                                            style={{backgroundColor: `${insight.color}`, width: '100%'}}
                                        />
                                        <p>#{insight.region}</p>
                                        <h3>{insight.title}</h3>
                                        <p>{insight.description}</p>
                                        <ArrowOutwardIcon
                                            style={{position: 'absolute', right: '16px', top: '24px', color: '#1a90ff', fontSize: '24px'}}
                                        />
                                    </Item>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </div>}
        </>
    );
}

export default Cards;

