import { useCallback } from 'react';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbar } from '@mui/x-data-grid-pro';
import { GRID_TREE_DATA_GROUPING_FIELD, useGridApiRef } from "@mui/x-data-grid-pro";

const getTreeDataPath = (row) => row.hierarchy;

function CustomToolbar() {
  return (
  <GridToolbarContainer>
    <GridToolbarColumnsButton/>
  </GridToolbarContainer>
  );
}

const AdminMode = (props) => {
  
  const { 
    showLoader,
    liveRows,
    liveColumns,
    processRowUpdate,
    retailerIdMarketIdRef,
    editable=true,
  } = props;

  const apiRef = useGridApiRef();
  const onCellClick = (params) => {
    if (params.isEditable && params.cellMode !== 'edit') {
      apiRef.current.startCellEditMode({ id: params.id, field: params.field });
    }
  }

  const onCellEditStop = (params , event) => {
    retailerIdMarketIdRef.current = params.field;
  }
  
  const onProcessRowUpdateError = useCallback((err) => {
    console.log("Error: ", err);
  }, []);
  
  return (
  <DataGridPro
    className={showLoader ? 'loading-state' : 'loaded-state'}
    // slots={{
    //   toolbar: CustomToolbar,
    // }}
    slots={{
      Toolbar: GridToolbar,
    }}
    treeData
    rows={liveRows}
    columns={liveColumns}
    getTreeDataPath={getTreeDataPath}
    defaultGroupingExpansionDepth={editable ? 4 : 1}
    groupingColDef={{
      headerName: "Channel / Capability",
      width: 300
    }}
    initialState={{
      pinnedColumns: {
        left: editable ? [GRID_TREE_DATA_GROUPING_FIELD, 'weight', 'max_score', 'explanation'] : [GRID_TREE_DATA_GROUPING_FIELD, 'max_score', 'explanation'],
      },
      columns: {
        columnVisibilityModel: {
          max_score: false,
          weight: editable ? true : false,
        },
      },
    }}
    isCellEditable={(params) => (editable && (params.row.editable === true || params.field === 'weight'))}
    onCellEditStop={onCellEditStop}
    processRowUpdate={processRowUpdate}
    onProcessRowUpdateError={onProcessRowUpdateError}
    disableSelectionOnClick
    disableColumnSelector
    disableColumnMenu
    rowHeight={54}
    autoHeight={false}
    onCellClick={onCellClick}
    apiRef={apiRef}
    experimentalFeatures={{newEditingApi: true}}
    />
  )
}

export default AdminMode;