import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";

function SignOutButton (props) {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  return (
    <Button variant="outlined" onClick={() => handleLogout("redirect")}>Log out</Button>
  );
}

export default SignOutButton;
