import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ResponsiveBar } from '@nivo/bar'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { RevenueData } from '../../../../data/NivoData';
import { SortedDescendingIcon, SortedAscendingIcon, UnsortedIcon } from '../../utils'
import { hackGrid } from '../../utils';
import { cardContentMargin } from '../../styles'
import './styles/Revenue.css';

const cellRenderer = (params) => {
  return <span style={{ backgroundColor: '#EEEEEE', padding: '4px 6px', borderRadius: '4px' }}>{`${params.value}%`}</span>;
};

const columns: GridColDef[] = [
  { field: 'year', headerName: 'Year', width: 115 },
  { field: 'yoy', headerName: 'YoY Growth', sortComparator: (a, b) => parseFloat(a) - parseFloat(b), align: 'left', width: 150, renderCell: cellRenderer },
];

const INITIALIZE_DATE_INTERVAL_VALUE = 3;

const Revenue = () => {

  const [dateRange, setDateRange] = useState(INITIALIZE_DATE_INTERVAL_VALUE);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    // Add YoY to NivoBarData
    let previousValue = RevenueData[0].value;
    for (let i = 0; i < RevenueData.length; i++) {
      if (i === 0) {
        RevenueData[i].yoy = 0;
      } else {
        RevenueData[i].yoy = ((RevenueData[i].value - previousValue)/previousValue * 100).toFixed(2);
        previousValue = RevenueData[i].value;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setMaxValue(Math.max(...data.map(a => a.value)));
  }, [data]);

  useEffect(() => {
    filter(dateRange);

    hackGrid();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  const handleChange = (event) => {
    setDateRange(event.detail.value);
  }

  const filter = (range) => {
    const yearFrom = dayjs().year() - range + 1;
    setData(RevenueData.filter((item) => item.year >= yearFrom));
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: cardContentMargin }}>
          <div>
            <div className='h2'>
              Revenue - historic, current, forecast
            </div>
            <div className='h5' style={{ marginTop: '4px' }}>
              eCommerce net sales
            </div>
            <div className='h6' style={{ marginTop: '4px' }}>
              IN MILLION EUR
            </div>
          </div>
          <div className='rev-dropdown-wrap'>
            {/*<div className='h5' style={{ marginTop: '8px' }}>*/}
            {/*  Date Range*/}
            {/*</div>*/}
            <div style={{width: '150px', marginTop: '10px' }}>
              <WppSelect
                onWppChange={handleChange}
                placeholder="last 3 years"
                value={dateRange}
              >
                <WppListItem value="3" key={3}>
                  <p slot="label">last 3 years</p>
                </WppListItem>
                <WppListItem value="5" key={5}>
                  <p slot="label">last 5 years</p>
                </WppListItem>
              </WppSelect>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '80%', height:'420px', marginTop: '50px' }}>
            <ResponsiveBar
              data={data}
              keys={[
                'value'
              ]}
              indexBy="year"
              colors='#C880B2'
              padding={0.2}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: 18, // Label text size
                      fill: "#444444"
                    }
                  }
                },
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={null}
              enableGridY={true}
              gridYValues={[0, .01]}
              maxValue={maxValue + maxValue * 0.1}
              enableLabel={false}
              layers={[
                'grid',
                'axes',
                'bars',
                'markers',
                'legends',
                'annotations',
                ({ bars }) => {
                  return (
                    // labels above the bars
                    <g>
                      {bars.map(({ width, height, x, y, data }) => {
                        return (
                          <text
                            transform={`translate(${width / 2 + x - 10}, ${y - 15})`}
                            style={{ fontSize: '20px', fill: '#444444' }}
                          >
                            {`${data.value ?? ''}`}
                          </text>
                        );
                      })}
                    </g>
                  );
                },
            ]}
            />
          </div>
          {/* Tabular Data */}
          <div style={{ width: '20%', margin: '80px 0 0 0' }}>
            <div className='h2'>
              YoY Growth
            </div>
            <div className='h5' style={{ marginTop: '4px' }}>
              in percentage
            </div>
            <div style={{ height: 400, width: '80%' }}>
              <DataGridPro
                getRowId={(row) => row.year}
                rows={data}
                columns={columns}
                rowHeight={50}
                disableColumnMenu={true}
                hideFooter={true}
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textTransform: 'none',
                    fontWeight: '800',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    marginLeft: '0px',
                    visibility: 'visible !important',
                    width: 'auto !important',
                  },
                }}
                slots={{
                  columnSortedDescendingIcon: SortedDescendingIcon,
                  columnSortedAscendingIcon: SortedAscendingIcon,
                  columnUnsortedIcon: UnsortedIcon,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Revenue
