import _ from 'lodash';
import React, {useEffect, useState, useRef } from 'react';
import ReactGA from "react-ga4";
import { Button, Box, Tab, Grid, CircularProgress, LinearProgress, Switch, Collapse }  from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FilterListIcon from '@mui/icons-material/FilterList';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Header from "../../components/Header";
import SuperSearch from "../../components/SuperSearch";
import SideBar from "../../components/SideBar";
import SnackbarComponent from './components/Snackbar';
import MenuGrid from './components/MenuGrid';
import Filters from './components/Filters';
import AdminMode from './components/AdminMode';
import SummaryViewCard from './components/SummaryViewCard';
import FormDialog from './components/FormDialog';
import { transformData } from './utils';
import TimeMachine from './components/TimeMachine';
import NivoScatter from "../../components/NivoScatter";
import NivoRadar from "../../components/NivoRadar";
import { hackGrid } from './utils';
import '../../stylesheets/InsightRtmV2Page.css'
import '../../stylesheets/InsightPage.css'

const Tabs =  { 
  Summary: '1',
  Detail: '2',
  Admin: '3'
}

const InsightsRtmPage = (props) => {

  const { title, userName, userEmail } = props;

  const [value, setValue] = useState('1');
  const [liveRows, setLiveRows] = useState([]);
  const [liveColumns, setLiveColumns] = useState([]);
  const [liveColumnsOg, setLiveColumnsOg] = useState([]);
  const [isLiveRowsLoaded, setLiveRowsLoaded] = useState(false);
  const [filtersData, setFiltersData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [marketIds, setMarketIds] = useState([]);
  const [channels, setChannels] = useState([]);
  const [liveChannels, setLiveChannels] = useState([]);
  const [types, setTypes] = useState([]);
  const [liveTypes, setLiveTypes] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [cardDataOg, setCardDataOg] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [superSearchValues, setSuperSearchValues] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [gridEdited, setGridEdited] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scoredOnly, setScoredOnly] = useState(true);
  const [newRetailers, setNewRetailers] = useState([]);
  const [formType, setFormType] = useState('');
  const [timeMachineVisible, setTimeMachineVisible] = useState(false);

  // The most recently touched/edited retailer_market
  const retailerIdMarketIdRef = useRef('');
  const dataSave = useRef({});
     
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === Tabs.Detail || newValue === Tabs.Admin ) {
      hackGrid()
    }
  };

  const handleFilterChange = (event, newValue) => {
    showFilters ? setShowFilters(false) : setShowFilters(true)
  }

  const handleDialogOpen = (type) => {
    setDialogOpen(true);
    setFormType(type);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    
    ReactGA.send({hitType: "pageview", page: window.location.pathname, title: title});
    setGridEdited(false);
    
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });
    
    try {
      fetch('https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/filters', {
        method: 'GET',
      })
      .then(response => response.json())
      .then(data => {
        setFiltersData(data)
        generateFilters(data)
      })
    } catch (error) {
      console.log('API FETCH ERROR: ', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    if (gridEdited) {
      setSnackOpen(true)
    }
    else {
      setSnackOpen(false)
    }
  }, [gridEdited])
  
  useEffect(() => {
    hackGrid()
  }, [liveRows])

  useEffect(() => {
    if (userEmail.length > 0) {
      getGridData()
      getSummaryData()
    }

    if (!newRetailers.length) {
      setSuperSearchValues([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketIds, liveChannels, liveTypes, userEmail, scoredOnly, newRetailers])

  const getGridData = () => {

    if (marketIds[0] === -1 || liveChannels[0] === -1 || liveTypes[0] === -1) {
      setLiveRows([]);
      setLiveColumns([]);
      return;
    }
    setShowLoader(true)
    fetch(`https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1?scoredOnly=${scoredOnly ? '1' : '0'}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
      body: JSON.stringify({
        "filters": {
          "markets": marketIds,
          "channels": liveChannels,
          "types": liveTypes,
        },
        "user": {
          "email": userEmail
        }
      }),
    })
      .then(response => response.json())
      .then(data => {
          transformData(data)
          setLiveRows(data.rows);
          setLiveColumns(data.columns);
          setLiveColumnsOg(data.columns);
          setLiveRowsLoaded(true);
          setIsAdmin(data.user.admin)
          setShowLoader(false)
        }
      )
  }

  const getSummaryData = () => {

    if (marketIds[0] === -1 || liveChannels[0] === -1 || liveTypes[0] === -1) {
      setCardData([])
      setCardDataOg([])
      return;
    }

    fetch(`https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/summary?scoredOnly=${scoredOnly ? '1' : '0'}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
      body: JSON.stringify({
        "filters": {
          "markets": marketIds,
          "channels": liveChannels,
          "types": liveTypes,
        }
      }),
    })
      .then(response => response.json())
      .then(data => {
          setCardData(data)
          setCardDataOg(data)
        }
      )
  }

  const generateFilters = (data) => {
    let regions = []
    let markets = []
    let channels = []
    let types = []
    data.regions.forEach((region) => {
      regions.push(region.name)
    })
    data.markets.forEach((market) => {
      markets.push(market.name)
    })
    data.channels.forEach((channel) => {
      channels.push(channel.name)
    })
    data.types.forEach((type) => {
      types.push(type.name)
    })
    setRegions(regions.sort())
    setMarkets(markets.sort())
    setChannels(channels)
    setTypes(types)
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    if (_.get(oldRow, retailerIdMarketIdRef.current) !== _.get(newRow, retailerIdMarketIdRef.current)){
      dataSave.current[retailerIdMarketIdRef.current + ',' + newRow.offeringId] = newRow[retailerIdMarketIdRef.current];
      setGridEdited(true);
    }
    return updatedRow;
  };  

  const updateDB = (e) => {
    let data = [];
    _.forOwn(dataSave.current, (value, key) => {
      const field_offeringId = key.split(',');
      data.push({ field: field_offeringId[0], new_score: value, offeringId: parseInt(field_offeringId[1]) })
     });
    let user = {name: userName, email: userEmail}
    try {
      fetch('https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/save', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        body: JSON.stringify({
          "data": data,
          "user": user
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.Message === 'Success') {
          setGridEdited(false);
          retailerIdMarketIdRef.current = '';
          dataSave.current = {};
        } else {
          console.log('Error: ', data);
        }
      })
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const scoredOnlyChange = (event) => {
    setScoredOnly(event.target.checked);
    if (event.target.checked) {
      setSuperSearchValues([]);
    }
  };

  return (
    <div style={{height: 500, width: '100%', position: 'relative'}}>
      <SideBar/>
      <div className={'page-wrapper'}>
        <Header
          title="[Retail Media Capabilities]"
          userName={userName}
        />
        <div className={showLoader ? 'loading-state' : 'loaded-state'}>
          <div className="col-1-1 grad">
            <div className={'scatter-overlay'}></div>
            <div className={'col-1-3'}>
              {/*<NivoRadar/>*/}
            </div>
            <div className={'col-2-3'}>
              {/*<NivoScatter*/}
              {/*  filtersData={filtersData}*/}
              {/*  cardData={cardData}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
        {showLoader && <LinearProgress className={'linear-loader'}/>}
        <div className={'col-1-1 margin-t-42 top-curve'}>
          <div className={showFilters ? 'filter-icon color-blue' : 'filter-icon'}
             onClick={handleFilterChange}>
            <FilterListIcon />
          </div>
        </div>
        <div className={showFilters ? 'filters-wrap show-filters' : 'filters-wrap hide-filters'}>
          <Filters
            regions={regions}
            markets={markets}
            channels={channels}
            types={types}
            setSuperSearchValues={setSuperSearchValues}
            setLiveChannels={setLiveChannels}
            setLiveTypes={setLiveTypes}
            setMarkets={setMarkets}
            setMarketIds={setMarketIds}
            SuperSearch={SuperSearch}
            filtersData={filtersData}
          />
          <div className={'col-1-1 margin-t-16 flex-center-all padding-lr-64 mob-only'}>
            <SuperSearch
              setLiveColumns={setLiveColumns}
              liveColumnsOg={liveColumnsOg}
              setCardData={setCardData}
              cardDataOg={cardDataOg}
              superSearchValues={superSearchValues}
              setSuperSearchValues={setSuperSearchValues}
              newRetailers={newRetailers}
            />
          </div>
        </div>
        <div className={'col-1-1 margin-t-42 flex-direction-column padding-lr-64'}>
          <TabContext value={value}>
            <Box className={'mob-only'} sx={{borderBottom: 1, borderColor: 'divider', my: 0, mx: 4, display: 'flex', justifyContent: 'space-between'}}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Summary View" value="1"/>
                <Tab label="Details View" value="2"/>
                {isAdmin ? <Tab label="Admin Mode" value="3" /> : null}
              </TabList>
            </Box>
            <TabPanel value="1">
              {isLiveRowsLoaded ?
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}
                    className={showLoader ? 'loading-state' : 'loaded-state'}
                    >
                    {cardData.map((card, index) => (
                      <SummaryViewCard
                        key={index}
                        index={index}
                        card={card}
                      />
                    ))}
                </Grid>
                :
                <div className={'col-1-1 flex-center-all'}>
                  <CircularProgress style={{marginTop: '100px'}}/>
                </div>
              }
            </TabPanel>
            <TabPanel value="2">
              <div className={'col-1-1 grid-wrap'} style={{height: '600px'}}>
                {isLiveRowsLoaded ? 
                  <AdminMode
                    showLoader={showLoader}
                    liveRows={liveRows}
                    liveColumns={liveColumns}
                    processRowUpdate={processRowUpdate}
                    retailerIdMarketIdRef={retailerIdMarketIdRef}
                    editable={false}
                  />
                  : <CircularProgress style={{marginTop: '200px'}}/>
                }
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className={'col-1-1 grid-wrap'} style={{height: '600px'}}>
                <div className={"controls-wrap"}>
                  <span className={"scored-retailers"}>Scored Only</span>
                  <Switch className={'all-switch'} checked={scoredOnly} onChange={scoredOnlyChange}/>
                  <MenuGrid
                    handleDialogOpen={handleDialogOpen}
                    setTimeMachineVisible={setTimeMachineVisible}
                    isAdmin={isAdmin}
                  />
                  <Button id="saveData" disabled={!gridEdited} sx={{height: '40px'}} onClick={updateDB} variant="outlined" color="warning" startIcon={<SaveAltIcon />}>Save Changes</Button>
                </div>
                  {isLiveRowsLoaded ?
                  <AdminMode
                    showLoader={showLoader}
                    liveRows={liveRows}
                    liveColumns={liveColumns}
                    processRowUpdate={processRowUpdate}
                    retailerIdMarketIdRef={retailerIdMarketIdRef}
                  />
                  : <CircularProgress style={{marginTop: '200px'}}/>
                }
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
      <div>
        <SnackbarComponent
          snackOpen={snackOpen}
          setSnackOpen={setSnackOpen}
          message="Changes made, don't forget to save them!"
        />
      </div>
      <Collapse orientation="horizontal" in={timeMachineVisible}>
        <TimeMachine
        timeMachineVisible={timeMachineVisible}
        setTimeMachineVisible={setTimeMachineVisible}
        />
      </Collapse>
      <FormDialog
        userEmail={userEmail}
        handleDialogClose={handleDialogClose}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        formType={formType}
        setNewRetailers={setNewRetailers}
        scoredOnly={scoredOnly}
        setScoredOnly={setScoredOnly}
        filtersData={filtersData}
        cardData={cardData}
      />
    </div>
  );
}

export default InsightsRtmPage;
