import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import SnackbarComponent from './Snackbar';
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import TimeMachineMenu from "./TimeMachineMenu";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Typography,
  Button, 
  Dialog, 
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import '../../../stylesheets/TimeMachine.css';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const TimeMachine = (props) => {

  const { timeMachineVisible, setTimeMachineVisible } = props;

  const [data, setData] = useState([]);
  const [preview, setPreview ] = useState({});
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [includeExcludeTrigger, setIncludeExcludeTrigger] = useState(false);
  
  useEffect(() => {
    if (timeMachineVisible) {
      fetch('https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/insights/v2/1/history', {
        method: 'GET',
      })
      .then(response => response.json())
      .then(data => {
        setData(data.changes);
      })
      .catch((error) => {
        console.log('TimeMachine Data fetch error:', error);
      });
    }
  }, [timeMachineVisible, includeExcludeTrigger])


  const handleClose = () => {
    setPreview({});
  };

  return (
    <div className={'time-machine-wrapper'}>
      <div className={timeMachineVisible ? 'time-machine-header-close time-machine-on' : 'time-machine-header-close time-machine-off'} onClick={() => { setSnackOpen(false); setTimeMachineVisible(false); }}>
        <KeyboardDoubleArrowRightTwoToneIcon fontSize={'small'} />
      </div>
      <div className={timeMachineVisible ? 'time-machine time-machine-on' : 'time-machine time-machine-off'}>
        <div className={'time-machine-header'}>
          <h4>TIME MACHINE</h4>
        </div>
        {data.map((item, index) => (
          <div key={index} className={`time-machine-commit ${!item.completed && 'pending-commit'}`}>
            {item.completed 
              ? <VisibilityIcon style={{'width': '20px', 'color': '#5cb55f'}}/>
              : <VisibilityOffTwoToneIcon style={{'width': '20px'}} color={'secondary'}/>
            }
            <span>{item.userName} &middot; <em>{dayjs(item.timestamp).fromNow()}</em></span>
            <TimeMachineMenu
              userName={item.userName}
              timeStamp={dayjs(preview.timeStamp).format('llll')}
              completed={item.completed}
              changeId={item.changeId}
              changes={{userName: item.userName, timeStamp: item.timestamp, changes: item.changes}}
              setPreview={setPreview}
              setSnackMessage={setSnackMessage}
              setSnackOpen={setSnackOpen}
              includeExcludeTrigger={includeExcludeTrigger}
              setIncludeExcludeTrigger={setIncludeExcludeTrigger}
            />
          </div>
        ))}

        {
          Object.keys(preview).length > 0 &&
          <Dialog open={!!Object.keys(preview).length} onClose={handleClose} scroll='paper' fullWidth={true} maxWidth={"sm"}>
            <DialogTitle>Changes</DialogTitle>
            <DialogContent dividers={true}>
              <List sx={{ pt: 0, my: 0, mx: .5 }}>
                <ListItem disableGutters>
                  <ListItemText primary={preview.userName} />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText primary={dayjs(preview.timeStamp).format('llll')} />
                </ListItem>
                {preview.changes.map((item, index) => (
                  <ListItem key={index} disableGutters>
                    <ListItemText
                      primary={
                        <Typography>
                          <div style={{ display: 'flex' }}><div style={{ width: '80px' }}>Retailer:</div>{item.retailerName}</div>
                          <div style={{ display: 'flex' }}><div style={{ width: '80px' }}>Market:</div>{item.marketCode}</div>
                          <div style={{ display: 'flex' }}><div style={{ width: '80px' }}>Offering:</div>{item.offering}</div>
                          <div>{`New Score: ${item.new_score}`}&nbsp;&nbsp;&nbsp;&nbsp;{`Orig Score: ${item.old_score}`}</div>
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>OK</Button>
            </DialogActions>
          </Dialog>
        }
        <SnackbarComponent
          snackOpen={snackOpen}
          setSnackOpen={setSnackOpen}
          message={snackMessage}
        />
      </div>
    </div>
  );
}

export default TimeMachine;