export const insightCardData = [
  {
    media: [
      {
        type: "rtm-capabilities",
        title: "Retail Media Capabilities",
        description: "Analysis of 185 retail media platforms, weighted and scored by our staff.",
        modified: "2022-10-15",
        region: "Global",
        color: '#1a90ff',
        path: '/insight/rtmV2',
      },
      {
        type: "bid-management",
        title: "Retail Bid Management Tech Evaluation",
        description: "Analysis of bid management platforms, weighted and scored by our staff.",
        modified: "2022-10-01",
        region: "Global",
        color: '#1a90ff',
        path: '/insight/bid',
      },
      // {
      //   type: "dsp-capabilities",
      //   title: "DSP Capabilities",
      //   description: "Tech based analysis of DSPs, weighted and scored by our staff.",
      //   modified: "2022-10-01",
      //   region: "North America",
      //   color: '#1a90ff',
      //   path: '/insight/dsp',
      // },
      // {
      //   type: "rtm-evaluation",
      //   title: "Coca Cola 2022 Retail Media Evaluation",
      //   description: "Analysis of 19 retail media platforms, weighted and scored by the Coca Cola team.",
      //   modified: "2022-10-15",
      //   region: "North America",
      //   color: '#ffb52b',
      //   path: 'https://flow.fusionplatform.io',
      // },
      // {
      //   type: "rtm-evaluation",
      //   title: "Coca Cola 2023 Retail Media Evaluation",
      //   description: "Analysis of 19 retail media platforms, weighted and scored by the Coca Cola team.",
      //   modified: "2022-10-15",
      //   region: "North America",
      //   color: '#ffb52b',
      //   path: 'https://flow.fusionplatform.io',
      // },
      // {
      //   type: "social-brand-safety",
      //   title: "Brand Safety on Social Media",
      //   description: "Risk assessment of social media placements compiled by our staff.",
      //   modified: "2022-12-07",
      //   region: "Global",
      //   color: '#1a90ff',
      //   path: '#',
      // },
    ]
  },
  {
    technology: [
      {
        type: "fpo",
        title: "Lorum Ipsum",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        modified: "2022-10-15",
        region: "North America",
      }
    ]
  }
]

export const workSpaces = [
  {
    essenceMediacom: {
      users: ['*@groupm.com', '*@essencemediacom.com'],
      workspaces: [
        {
          name: 'coca cola',
          users: ['john@tccc.com', 'jack@tccc.com'],
        },
        {
          name: 'mars',
          users: ['pranav@mars.com', 'teo@mars.com'],
        },
      ]
    }
  }
]

export const tree = [
  {
    GroupM: {
      workspaces: ['coca cola', 'mars', 'nestle', 'unilever']
    }
  },
  {
    EssenceMediacom: {
      workspaces: ['coca cola', 'mars']
    }
  },
  {
    Mindshare: {
      workspaces: ['nestle', 'unilever']
    }
  }
]

export const users = [
  {
    write: ['*@groupm.com', '*@essencemediacom.com']
  },
  {
    read: [
      {
        'john@tccc.com': {
          workspaces:
            ['coca cola','mars']
        }
      },
      {
        'john@tccc.com': {
          workspaces:
            ['coca cola']
        }
      },
    ]
  },
]

