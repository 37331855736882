import React, { useState, useEffect} from 'react';

const TopLevelStats = (props) => {

  const [data, setData] = useState([
    {
      'Global Net Sales': '$742',
      'Global Rank': '#10',
      'Main Country': 'France',
      'Main Category': 'Food and Beverages',
      'Launch Year': '1999'
    }
  ]);

  return (
    <div className={'row col-1-1 flex-direction-row'}>
      <div className={'col-1-5 flex-direction-column flex-center-all exp-box'}>
        <h5>Global Net Sales<br/>(2021)</h5>
        <h3>{data[0]['Global Net Sales']}</h3>
      </div>
      <div className={'col-1-5 flex-direction-column flex-center-all exp-box'}>
        <h5>Global Rank<br/>(2021)</h5>
        <h3>{data[0]['Global Rank']}</h3>
      </div>
      <div className={'col-1-5 flex-direction-column flex-center-all exp-box'}>
        <h5>Main Country</h5>
        <h3>{data[0]['Main Country']}</h3>
      </div>
      <div className={'col-1-5 flex-direction-column flex-center-all exp-box'}>
        <h5>Main Category</h5>
        <h3>{data[0]['Main Category']}</h3>
      </div>
      <div className={'col-1-5 flex-direction-column flex-center-all exp-box'}>
        <h5>Launch Year</h5>
        <h3>{data[0]['Launch Year']}</h3>
      </div>
    </div>
  )
}

export default TopLevelStats;